@media only screen and (max-width: 480px) {
    .training_quiz_question {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question_content {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 6.666vw 4.444vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 0px;
    }

    .training_quiz_question_title {
        font-weight: 900;
        font-size: 3.333vw;
        line-height: 5vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 2.222vw;
    }

    .training_quiz_question_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 6.666vw;
    }

    .training_quiz_question_question {
        font-weight: 700;
        font-size: 5.555vw;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-top: 2.222vw;
        margin-bottom: 6.666vw;
    }

    .training_quiz_question_actions {
        display: flex;
        gap: 4.444vw;
        margin-bottom: 8.888vw;
    }

    .training_quiz_question_action_btn {
        flex-grow: 1;
        background-color: #E5E5E5;
        border-radius: 8px;
        height: 17.777vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 5vw;
        line-height: 6.111vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: black;
        outline: none;
        border: 0px;
    }

    .training_quiz_question_action_btn.active {
        background-color: #8BD41F;
        color: black;
    }

    .training_quiz_question_footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4.444vw;
    }

    .training_quiz_question_footer .next_btn {
        width: 100%;
    }

    .training_quiz_question_footer .back_btn {
        width: 33.333vw;
    }

    .training_quiz_question_chechbox_lists {
        border: 1px solid #EDEDF0;
        border-radius: 4.444vw;
        margin-bottom: 8.888vw;
    }

    .training_quiz_question_chechbox_list {
        padding: 5.277vw 5.555vw;
        min-height: 16.666vw;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EDEDF0;
        gap: 4.444vw;
    }

    .training_quiz_question_chechbox_list.selected {
        background-color: #e2f4c7;
    }

    .training_quiz_question_chechbox_list:last-child {
        margin-bottom: 0px;
    }

    .training_quiz_question_chechbox_list input[type=checkbox] {
        flex: none;
        width: 5.555vw;
        height: 5.555vw;
        border: 1px solid #D9DBE0;
        border-radius: 4px;
        opacity: 0.5;
        accent-color: green;
    }

    .training_quiz_question_chechbox_list input[type=checkbox]:checked {
        opacity: 1;
    }

    .training_quiz_question_chechbox_list label {
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.111vw;
        display: flex;
        align-items: center;
        color: #262629;
        margin: 0px;
    }

    .training_quiz_question_radio_lists {
        border: 1px solid #EDEDF0;
        border-radius: 4.444vw;
        margin-bottom: 8.888vw;
    }

    .training_quiz_question_radio_list {
        padding: 5.277vw 5.555vw;
        min-height: 16.666vw;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EDEDF0;
        gap: 4.444vw;
    }

    .training_quiz_question_radio_list.selected {
        background-color: #e2f4c7;
    }

    .training_quiz_question_radio_list:last-child {
        margin-bottom: 0px;
    }

    .training_quiz_question_radio_list input[type=radio] {
        flex: none;
        width: 5.555vw;
        height: 5.555vw;
        border: 1px solid #D9DBE0;
        border-radius: 4px;
        opacity: 0.5;
        accent-color: green;
    }

    .training_quiz_question_radio_list input[type=radio]:checked {
        opacity: 1;
    }

    .training_quiz_question_radio_list label {
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.111vw;
        display: flex;
        align-items: center;
        color: #262629;
        margin: 0px;
    }

    .training_quiz_question_right {
        display: none;
    }

    .training_quiz_result_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_result_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_result_modal_body {
        height: fit-content;
        position: absolute;
        top: 28.611vw;
        z-index: 100;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100vw - 17.777vw);
    }

    .training_quiz_result_modal_content {
        width: 100%;
        padding: 9.444vw 11.666vw 6.388vw 11.666vw;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_quiz_result_modal_icon {
        width: 17.5vw;
        height: 17.5vw;
        margin-bottom: 4.944vw;
    }

    .training_quiz_result_modal_title {
        text-align: center;
        font-size: 10.111vw;
        color: black;
        font-weight: bold;
        margin-bottom: 4.611vw;
    }

    .training_quiz_result_modal_description {
        font-size: 3.055vw;
        text-align: center;
        line-height: 5.277vw;
        color: black;
        margin-bottom: 6.666vw;
        max-width: 100%;
        width: 100%;
    }

    .training_quiz_result_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 5.277vw;
        width: 100%;
    }

    .training_quiz_result_modal_actions>a {
        width: 100%;
    }

    .training_quiz_result_modal_actions>a button {
        height: 8.888vw !important;
        border-radius: 6px !important;
    }

    .training_quiz_result_modal_actions>a button span {
        font-weight: 400 !important;
        font-size: 3.333vw !important;
        line-height: 4.166vw !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .training_quiz_question {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_quiz_question_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 0.555vw;
    }

    .training_quiz_question_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.666vw;
    }

    .training_quiz_question_question {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-top: 0.555vw;
        margin-bottom: 2.222vw;
    }

    .training_quiz_question_actions {
        display: flex;
        gap: 1.666vw;
        margin-bottom: 2.222vw;
    }

    .training_quiz_question_action_btn {
        flex-grow: 1;
        background-color: #E5E5E5;
        border-radius: 8px;
        height: 4.444vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.25vw;
        line-height: 1.527vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: black;
        outline: none;
        border: 0px;
    }

    .training_quiz_question_action_btn.active {
        background-color: #8BD41F;
        color: black;
    }

    .training_quiz_question_footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1.111vw;
    }

    .training_quiz_question_footer .next_btn {
        width: 12.638vw;
    }

    .training_quiz_question_footer .back_btn {
        width: 8.333vw;
    }

    .training_quiz_question_chechbox_lists {
        border: 1px solid #EDEDF0;
        border-radius: 1.111vw;
        margin-bottom: 2.222vw;
    }

    .training_quiz_question_chechbox_list {
        padding: 1.319vw 1.388vw;
        min-height: 4.166vw;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EDEDF0;
        gap: 1.111vw;
    }

    .training_quiz_question_chechbox_list.selected {
        background-color: #e2f4c7;
    }

    .training_quiz_question_chechbox_list:last-child {
        margin-bottom: 0px;
    }

    .training_quiz_question_chechbox_list input[type=checkbox] {
        flex: none;
        width: 1.388vw;
        height: 1.388vw;
        border: 1px solid #D9DBE0;
        border-radius: 4px;
        opacity: 0.5;
        accent-color: green;
    }

    .training_quiz_question_chechbox_list input[type=checkbox]:checked {
        opacity: 1;
    }

    .training_quiz_question_chechbox_list label {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        color: #262629;
        margin: 0px;
    }

    .training_quiz_question_radio_lists {
        border: 1px solid #EDEDF0;
        border-radius: 1.111vw;
        margin-bottom: 2.222vw;
    }

    .training_quiz_question_radio_list {
        padding: 1.319vw 1.388vw;
        min-height: 4.166vw;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EDEDF0;
        gap: 1.111vw;
    }

    .training_quiz_question_radio_list.selected {
        background-color: #e2f4c7;
    }

    .training_quiz_question_radio_list:last-child {
        margin-bottom: 0px;
    }

    .training_quiz_question_radio_list input[type=radio] {
        flex: none;
        width: 1.388vw;
        height: 1.388vw;
        border: 1px solid #D9DBE0;
        border-radius: 4px;
        opacity: 0.5;
        accent-color: green;
    }

    .training_quiz_question_radio_list input[type=radio]:checked {
        opacity: 1;
    }

    .training_quiz_question_radio_list label {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        color: #262629;
        margin: 0px;
    }

    .training_quiz_question_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 7 / span 2;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_quiz_question_right_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .training_quiz_question_right_progress {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .training_quiz_question_right_progress_item {
        height: 2.222vw;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_question_right_progress_item.active {
        color: black;
    }

    .training_quiz_question_right_progress_item:not(.with_icon) {
        padding-left: 1.944vw;
    }

    .training_quiz_question_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
        background-color: #8BD41F;
    }

    .training_quiz_question_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
        background-color: #8BD41F;
    }

    .training_quiz_question_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0.555vw;
        width: 0.555vw;
        border-radius: 2px;
        height: 100%;
        background-color: #515157;
        opacity: 0.2;
    }

    .training_quiz_question_right_progress_item.with_icon {
        gap: 0.277vw;
    }

    .training_quiz_question_right_progress_item.with_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_quiz_result_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_result_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_result_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 12.569vw;
        left: 50%;
        transform: translateX(-50%);
        width: 36.736vw;
    }

    .training_quiz_result_modal_content {
        width: 100%;
        padding: 2.662vw 1.666vw 2.013vw 1.666vw;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_quiz_result_modal_icon {
        width: 10.742vw;
        height: 8.341vw;
        margin-bottom: 0.861vw;
    }

    .training_quiz_result_modal_title {
        text-align: center;
        font-size: 2.777vw;
        color: black;
        font-weight: bold;
        margin-bottom: 0.384vw;
    }

    .training_quiz_result_modal_description {
        font-size: 1.111vw;
        text-align: center;
        line-height: 110%;
        color: black;
        margin-bottom: 2.013vw;
        max-width: 20.833vw;
    }

    .training_quiz_result_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 1.597vw;
    }

    .training_quiz_result_modal_actions>a {
        width: 19.861vw;
    }

    .training_quiz_result_modal_actions>a button span {
        font-weight: 400;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .training_quiz_question {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_quiz_question_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 0.555vw;
    }

    .training_quiz_question_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.666vw;
    }

    .training_quiz_question_question {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-top: 0.555vw;
        margin-bottom: 2.222vw;
    }

    .training_quiz_question_actions {
        display: flex;
        gap: 1.666vw;
        margin-bottom: 2.222vw;
    }

    .training_quiz_question_action_btn {
        flex-grow: 1;
        background-color: #E5E5E5;
        border-radius: 8px;
        height: 4.444vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.25vw;
        line-height: 1.527vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: black;
        outline: none;
        border: 0px;
    }

    .training_quiz_question_action_btn.active {
        background-color: #8BD41F;
        color: black;
    }

    .training_quiz_question_footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1.111vw;
    }

    .training_quiz_question_footer .next_btn {
        width: 12.638vw;
    }

    .training_quiz_question_footer .back_btn {
        width: 8.333vw;
    }

    .training_quiz_question_chechbox_lists {
        border: 1px solid #EDEDF0;
        border-radius: 1.111vw;
        margin-bottom: 2.222vw;
    }

    .training_quiz_question_chechbox_list {
        padding: 1.319vw 1.388vw;
        min-height: 4.166vw;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EDEDF0;
        gap: 1.111vw;
    }

    .training_quiz_question_chechbox_list.selected {
        background-color: #e2f4c7;
    }

    .training_quiz_question_chechbox_list:last-child {
        margin-bottom: 0px;
    }

    .training_quiz_question_chechbox_list input[type=checkbox] {
        flex: none;
        width: 1.388vw;
        height: 1.388vw;
        border: 1px solid #D9DBE0;
        border-radius: 4px;
        opacity: 0.5;
        accent-color: green;
    }

    .training_quiz_question_chechbox_list input[type=checkbox]:checked {
        opacity: 1;
    }

    .training_quiz_question_chechbox_list label {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        color: #262629;
        margin: 0px;
    }

    .training_quiz_question_radio_lists {
        border: 1px solid #EDEDF0;
        border-radius: 1.111vw;
        margin-bottom: 2.222vw;
    }

    .training_quiz_question_radio_list {
        padding: 1.319vw 1.388vw;
        min-height: 4.166vw;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EDEDF0;
        gap: 1.111vw;
    }

    .training_quiz_question_radio_list.selected {
        background-color: #e2f4c7;
    }

    .training_quiz_question_radio_list:last-child {
        margin-bottom: 0px;
    }

    .training_quiz_question_radio_list input[type=radio] {
        flex: none;
        width: 1.388vw;
        height: 1.388vw;
        border: 1px solid #D9DBE0;
        border-radius: 4px;
        opacity: 0.5;
        accent-color: green;
    }

    .training_quiz_question_radio_list input[type=radio]:checked {
        opacity: 1;
    }

    .training_quiz_question_radio_list label {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        color: #262629;
        margin: 0px;
    }

    .training_quiz_question_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 10 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_quiz_question_right_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .training_quiz_question_right_progress {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .training_quiz_question_right_progress_item {
        height: 2.222vw;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_question_right_progress_item.active {
        color: black;
    }

    .training_quiz_question_right_progress_item:not(.with_icon) {
        padding-left: 1.944vw;
    }

    .training_quiz_question_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
        background-color: #8BD41F;
    }

    .training_quiz_question_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
        background-color: #8BD41F;
    }

    .training_quiz_question_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0.555vw;
        width: 0.555vw;
        border-radius: 2px;
        height: 100%;
        background-color: #515157;
        opacity: 0.2;
    }

    .training_quiz_question_right_progress_item.with_icon {
        gap: 0.277vw;
    }

    .training_quiz_question_right_progress_item.with_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_quiz_result_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_result_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_result_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 12.569vw;
        left: 50%;
        transform: translateX(-50%);
        width: 36.736vw;
    }

    .training_quiz_result_modal_content {
        width: 100%;
        padding: 2.662vw 1.666vw 2.013vw 1.666vw;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_quiz_result_modal_icon {
        width: 10.742vw;
        height: 8.341vw;
        margin-bottom: 0.861vw;
    }

    .training_quiz_result_modal_title {
        text-align: center;
        font-size: 2.777vw;
        color: black;
        font-weight: bold;
        margin-bottom: 0.384vw;
    }

    .training_quiz_result_modal_description {
        font-size: 1.111vw;
        text-align: center;
        line-height: 110%;
        color: black;
        margin-bottom: 2.013vw;
        max-width: 20.833vw;
    }

    .training_quiz_result_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 1.597vw;
    }

    .training_quiz_result_modal_actions>a {
        width: 19.861vw;
    }

    .training_quiz_result_modal_actions>a button span {
        font-weight: 400;
    }
}

@media only screen and (min-width: 1441px) {
    .training_quiz_question {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question_content {
        width: 100%;
        margin-left: 0px;
        padding: 40px;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 44px;
    }

    .training_quiz_question_title {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FF4600;
        margin-bottom: 8px;
    }

    .training_quiz_question_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 24px;
    }

    .training_quiz_question_question {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-top: 8px;
        margin-bottom: 32px;
    }

    .training_quiz_question_actions {
        display: flex;
        gap: 24px;
        margin-bottom: 32px;
    }

    .training_quiz_question_action_btn {
        flex-grow: 1;
        background-color: #E5E5E5;
        border-radius: 8px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.01em;
        color: black;
        outline: none;
        border: 0px;
    }

    .training_quiz_question_action_btn.active {
        background-color: #8BD41F;
        color: black;
    }

    .training_quiz_question_footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
    }

    .training_quiz_question_footer .next_btn {
        width: 182px;
    }

    .training_quiz_question_footer .back_btn {
        width: 120px;
    }

    .training_quiz_question_chechbox_lists {
        border: 1px solid #EDEDF0;
        border-radius: 16px;
        margin-bottom: 32px;
    }

    .training_quiz_question_chechbox_list {
        padding: 19px 20px;
        min-height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EDEDF0;
        gap: 16px;
    }

    .training_quiz_question_chechbox_list.selected {
        background-color: #e2f4c7;
    }

    .training_quiz_question_chechbox_list:last-child {
        margin-bottom: 0px;
    }

    .training_quiz_question_chechbox_list input[type=checkbox] {
        flex: none;
        width: 20px;
        height: 20px;
        border: 1px solid #D9DBE0;
        border-radius: 4px;
        opacity: 0.5;
        accent-color: green;
    }

    .training_quiz_question_chechbox_list input[type=checkbox]:checked {
        opacity: 1;
    }

    .training_quiz_question_chechbox_list label {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #262629;
        margin: 0px;
    }

    .training_quiz_question_radio_lists {
        border: 1px solid #EDEDF0;
        border-radius: 16px;
        margin-bottom: 32px;
    }

    .training_quiz_question_radio_list {
        padding: 19px 20px;
        min-height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EDEDF0;
        gap: 16px;
    }

    .training_quiz_question_radio_list.selected {
        background-color: #e2f4c7;
    }

    .training_quiz_question_radio_list:last-child {
        margin-bottom: 0px;
    }

    .training_quiz_question_radio_list input[type=radio] {
        flex: none;
        width: 20px;
        height: 20px;
        border: 1px solid #D9DBE0;
        border-radius: 4px;
        opacity: 0.5;
        accent-color: green;
    }

    .training_quiz_question_radio_list input[type=radio]:checked {
        opacity: 1;
    }

    .training_quiz_question_radio_list label {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #262629;
        margin: 0px;
    }

    .training_quiz_question_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 10 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_quiz_question_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 32px 28px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_quiz_question_right_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 16px;
    }

    .training_quiz_question_right_progress {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .training_quiz_question_right_progress_item {
        height: 32px;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_quiz_question_right_progress_item.active {
        color: black;
    }

    .training_quiz_question_right_progress_item:not(.with_icon) {
        padding-left: 28px;
    }

    .training_quiz_question_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
        background-color: #8BD41F;
    }

    .training_quiz_question_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
        background-color: #8BD41F;
    }

    .training_quiz_question_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 8px;
        width: 8px;
        border-radius: 2px;
        height: 100%;
        background-color: #515157;
        opacity: 0.2;
    }

    .training_quiz_question_right_progress_item.with_icon {
        gap: 4px;
    }

    .training_quiz_question_right_progress_item.with_icon img {
        width: 24px;
        height: 24px;
    }

    .training_quiz_result_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_result_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_quiz_result_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 181px;
        left: 50%;
        transform: translateX(-50%);
        width: 529px;
    }

    .training_quiz_result_modal_content {
        width: 100%;
        padding: 38.34px 24px 29px 24px;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_quiz_result_modal_icon {
        width: 154.69px;
        height: 120.12px;
        margin-bottom: 12.4px;
    }

    .training_quiz_result_modal_title {
        text-align: center;
        font-size: 40px;
        color: black;
        font-weight: bold;
        margin-bottom: 5.54px;
    }

    .training_quiz_result_modal_description {
        font-size: 16px;
        text-align: center;
        line-height: 110%;
        color: black;
        margin-bottom: 29px;
        max-width: 300px;
    }

    .training_quiz_result_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 23px;
    }

    .training_quiz_result_modal_actions>a {
        width: 286px;
    }

    .training_quiz_result_modal_actions>a button span {
        font-weight: 400;
    }
}