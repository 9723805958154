@media only screen and (max-width: 480px) {
    .login_page {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        width: 100vw;
        min-height: 100vh;
        background-color: #efefef;
        padding-top: 17.611vw;
    }

    .login_page_form {
        margin: auto;
        width: calc(100% - 19.444vw);
    }

    .login_page_logo {
        width: 55vw;
        flex: none;
        margin-bottom: 12.777vw;
    }

    .login_page_input {
        font-size: 3.888vw;
        width: 100%;
        height: 9.444vw;
        padding-left: 1.388vw;
        border: 1px solid #ced4da;
        border-radius: 7px;
        outline: none;
        background-color: white;
        margin-bottom: 5vw;
    }

    .login_page_btn {
        border-radius: 8px;
        border: 0px;
        font-size: 3.333vw;
        padding: 2.222vw 3.333vw;
        height: 9.722vw;
        background-color: black;
        width: 100%;
        color: white;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: 1.944vw;
        margin-bottom: 4.166vw;
    }

    .login_page_btn:disabled {
        background-color: #B8B8B8;
        color: white;
    }

    .login_page_btn:disabled svg {
        color: white;
    }

    .login_page_btn svg {
        color: #b2fc00;
        position: absolute;
        right: 3.333vw;
        top: 50%;
        transform: translateY(-50%);
    }

    .login_page_microsoft_btn {
        width: 100%;
        height: 9.722vw;
        border-radius: 8px;
        background-color: black;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        gap: 4.166vw;
        font-size: 3.333vw;
        padding: 2.222vw 3.333vw;
        margin-bottom: 3.333vw;
    }

    .login_page_microsoft_btn svg {
        width: 4.444vw;
        height: 4.444vw;
    }

    .login_page_forgot_password {
        display: flex;
        justify-content: center;
    }

    .login_page_forgot_password a {
        font-size: 3.055vw;
        font-weight: 400;
        color: #515157;
        text-decoration: none;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .login_page {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        width: 100vw;
        min-height: 100vh;
        background-color: #efefef;
        padding-top: 8.541vw;
    }

    .login_page_form {
        margin: auto;
        width: 34.513vw;
    }

    .login_page_logo {
        width: 34.513vw;
        flex: none;
        margin-bottom: 3.333vw;
    }

    .login_page_input {
        font-size: 0.902vw;
        width: 100%;
        height: 2.361vw;
        padding-left: 0.347vw;
        border: 1px solid #ced4da;
        border-radius: 4px;
        outline: none;
        background-color: white;
        margin-bottom: 1.666vw;
    }

    .login_page_btn {
        border-radius: 5px;
        border: 0px;
        font-size: 0.972vw;
        padding: 0.222vw 0.833vw;
        height: 2.777vw;
        background-color: black;
        width: 100%;
        color: white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 1.041vw;
    }

    .login_page_btn:disabled {
        background-color: #B8B8B8;
        color: white;
    }

    .login_page_btn:disabled svg {
        color: white;
    }

    .login_page_btn svg {
        color: #b2fc00;
        position: absolute;
        right: 0.833vw;
        top: 50%;
        transform: translateY(-50%);
    }

    .login_page_microsoft_btn {
        width: 100%;
        height: 2.916vw;
        border-radius: 5px;
        background-color: black;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        gap: 1.041vw;
        font-size: 1.111vw;
        padding: 0.555vw 0.833vw;
        margin-bottom: 2.152vw;
    }

    .login_page_microsoft_btn svg {
        width: 1.111vw;
        height: 1.111vw;
    }

    .login_page_forgot_password {
        display: flex;
        justify-content: center;
    }

    .login_page_forgot_password a {
        font-size: 0.972vw;
        font-weight: 400;
        color: #515157;
        text-decoration: none;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .login_page {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        width: 100vw;
        min-height: 100vh;
        background-color: #efefef;
        padding-top: 8.541vw;
    }

    .login_page_form {
        margin: auto;
        width: 34.513vw;
    }

    .login_page_logo {
        width: 34.513vw;
        flex: none;
        margin-bottom: 3.333vw;
    }

    .login_page_input {
        font-size: 0.902vw;
        width: 100%;
        height: 2.361vw;
        padding-left: 0.347vw;
        border: 1px solid #ced4da;
        border-radius: 4px;
        outline: none;
        background-color: white;
        margin-bottom: 1.666vw;
    }

    .login_page_btn {
        border-radius: 5px;
        border: 0px;
        font-size: 0.972vw;
        padding: 0.222vw 0.833vw;
        height: 2.777vw;
        background-color: black;
        width: 100%;
        color: white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 1.041vw;
    }

    .login_page_btn:disabled {
        background-color: #B8B8B8;
        color: white;
    }

    .login_page_btn:disabled svg {
        color: white;
    }

    .login_page_btn svg {
        color: #b2fc00;
        position: absolute;
        right: 0.833vw;
        top: 50%;
        transform: translateY(-50%);
    }

    .login_page_microsoft_btn {
        width: 100%;
        height: 2.916vw;
        border-radius: 5px;
        background-color: black;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        gap: 1.041vw;
        font-size: 1.111vw;
        padding: 0.555vw 0.833vw;
        margin-bottom: 2.152vw;
    }

    .login_page_microsoft_btn svg {
        width: 1.111vw;
        height: 1.111vw;
    }

    .login_page_forgot_password {
        display: flex;
        justify-content: center;
    }

    .login_page_forgot_password a {
        font-size: 0.972vw;
        font-weight: 400;
        color: #515157;
        text-decoration: none;
    }
}

@media only screen and (min-width: 1441px) {
    .login_page {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        width: 100vw;
        min-height: 100vh;
        background-color: #efefef;
        padding-top: 123px;
    }

    .login_page_form {
        margin: auto;
        width: 497px;
    }

    .login_page_logo {
        width: 497px;
        flex: none;
        margin-bottom: 48px;
    }

    .login_page_input {
        font-size: 13px;
        width: 100%;
        height: 34px;
        padding-left: 5px;
        border: 1px solid #ced4da;
        border-radius: 4px;
        outline: none;
        background-color: white;
        margin-bottom: 24px;
    }

    .login_page_btn {
        border-radius: 5px;
        border: 0px;
        font-size: 14px;
        padding: 8px 12px;
        height: 40px;
        background-color: black;
        width: 100%;
        color: white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 15px;
    }

    .login_page_btn:disabled {
        background-color: #B8B8B8;
        color: white;
    }

    .login_page_btn:disabled svg {
        color: white;
    }

    .login_page_btn svg {
        color: #b2fc00;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
    }

    .login_page_microsoft_btn {
        width: 100%;
        height: 42px;
        border-radius: 5px;
        background-color: black;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        gap: 15px;
        font-size: 16px;
        padding: 8px 12px;
        margin-bottom: 31px;
    }

    .login_page_microsoft_btn svg {
        width: 16px;
        height: 16px;
    }

    .login_page_forgot_password {
        display: flex;
        justify-content: center;
    }

    .login_page_forgot_password a {
        font-size: 14px;
        font-weight: 400;
        color: #515157;
        text-decoration: none;
    }
}