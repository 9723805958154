@media only screen and (max-width: 480px) {
    .training_layout_header {
        position: relative;
        overflow: hidden;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #8BD41F;
        color: white;
        padding: 4.722vw 3.333vw 4.722vw 4.444vw;
        height: 16.111vw;
        position: fixed;
        width: 100vw;
        top: 0px;
        z-index: 9;
    }

    .training_layout_header_left {
        display: flex;
        align-items: center;
        z-index: 1;
        visibility: visible;
    }

    .training_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
        height: 6.666vw;
    }

    .training_layout_header_menu_icon {
        width: 6.666vw;
        height: 6.666vw;
    }

    .training_layout_header_title {
        z-index: 0;
        position: absolute;
        left: 0px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 7.111vw;
        letter-spacing: 0.01em;
    }

    .training_layout_header_right {
        z-index: 1;
        width: fit-content;
    }

    .training_layout_header_close_btn {
        color: white;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        gap: 1.666vw;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 5.555vw;
        letter-spacing: -0.01em;
    }

    .training_layout_header_close_icon {
        width: 6.666vw;
        height: 6.666vw;
    }

    .training_layout_header_profile_container {
        display: none;
        align-items: center;
        justify-content: center;
        gap: 1.944vw;
    }

    .training_layout_header_profile_logout_btn {
        border: 1px solid #DC143C;
        background-color: white;
        border-radius: 8px;
        color: black;
        padding: 1.388vw 3.333vw 1.388vw 5vw;
        height: 6.944vw;
        display: flex;
        align-items: center;
        gap: 5vw;
        font-size: 3.333vw;
        font-weight: 600;
    }

    .training_layout_header_profile_logout_btn[hidden] {
        display: none !important;
    }

    .training_layout_header_profile_logout_btn svg {
        color: #DC143C;
        font-size: 4.166vw;
    }

    .training_layout_header_profile_avatar {
        width: 11.111vw;
        height: 11.111vw;
        border: 2px solid white;
        border-radius: 9999px;
        cursor: pointer;
    }

    .training_layout_breadcrumb_close_btn {
        color: black;
        white-space: nowrap;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: none;
        align-items: center;
        gap: 1.666vw;
        font-weight: 700;
        font-size: 4.444vw;
        line-height: 5.555vw;
        letter-spacing: -0.01em;
    }

    .training_layout_breadcrumb_close_btn span {
        display: none;
    }

    .training_layout_breadcrumb_close_icon {
        width: 6.666vw;
        height: 6.666vw;
    }

    .training_layout_breadcrumb {
        padding-right: 3.333vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        z-index: 9;
        position: fixed;
        width: 100vw;
        top: 16.111vw;
    }

    .training_layout_breadcrumb_list {
        width: 100%;
        max-width: 100vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding: 5vw 6.666vw;
        display: flex;
        align-items: center;
        gap: 2.222vw;
        flex-wrap: wrap;
    }

    .training_layout_breadcrumb_item {
        display: flex;
        align-items: center;
        gap: 2.222vw;
        min-height: 6.111vw;
    }

    .training_layout_breadcrumb_label {
        font-weight: 700;
        font-size: 3.888vw;
        line-height: 6.111vw;
        letter-spacing: -0.01em;
        color: black;
        white-space: wrap;
    }

    .training_layout_breadcrumb_item.active .training_layout_breadcrumb_label {
        color: #DC143C;
    }

    .training_layout_breadcrumb_arrow_icon {
        width: 4.444vw;
        white-space: nowrap;
        height: 4.444vw;
    }

    .training_layout_body {
        padding-top: 42.777vw;
        padding-bottom: 4.444vw;
        background-color: #E5E5E5;
    }

    .training_layout_body.position-fixed {
        position: fixed !important;
    }

    .training_layout_sidebar {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: none;
    }

    .training_layout_sidebar_content {
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 10%, transparent 100%);
        border-radius: 16px;
        margin-bottom: 22.5vw;
    }

    .training_layout_sidebar_content_header {
        padding: 6.666vw 6.666vw 8.888vw 6.666vw;
        color: white;
    }

    .training_layout_sidebar_overview {
        display: flex;
        align-items: center;
        font-weight: 900;
        font-size: 3.333vw;
        color: black;
        text-transform: uppercase;
        line-height: 5vw;
        margin-bottom: 4.444vw;
    }

    .training_layout_sidebar_overview_icon {
        flex: none;
        width: 13.333vw;
        height: 13.333vw;
        margin-right: 6.666vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .training_layout_sidebar_overview_icon img {
        width: 6.666vw;
        height: 6.666vw;
    }

    .training_layout_sidebar_overview_icon.icon_menu img {
        width: 8.888vw;
        height: 8.888vw;
    }

    .training_layout_sidebar_overview_left_lessons {
        white-space: nowrap;
        margin-right: 4.444vw;
    }

    .training_layout_sidebar_overview_points {
        white-space: nowrap;
    }

    .training_layout_sidebar_overview_divider {
        height: 8.888vw;
        border-right: 2px solid #FFFFFF;
        opacity: 0.2;
        margin-right: 4.444vw;
    }

    .training_layout_sidebar_close_btn {
        background-color: transparent;
        border: 0px;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 4.444vw;
        top: 4.444vw;
    }

    .training_layout_sidebar_close_icon {
        width: 6.666vw;
        height: 6.666vw;
    }


    .training_layout_sidebar_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 8.611vw;
        line-height: 11.111vw;
        letter-spacing: -0.02em;
        text-shadow: 0px 2px 12px #0b0e1a14;
        margin-bottom: 6.666vw;
        color: black;
    }

    .training_layout_sidebar_progress_bar {
        display: flex;
        align-items: center;
        height: 2.222vw;
        gap: 1.666vw;
    }

    .training_layout_sidebar_progress_item {
        height: 100%;
        background: #FFFFFF;
        width: 100%;
        border-radius: 2px;
    }

    .training_layout_sidebar_progress_item.active {
        opacity: 1;
        background-color: black;
    }

    .training_layout_sidebar_content_body {
        padding: 8.888vw 7.777vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_layout_sidebar_categories {
        display: flex;
        flex-direction: column;
        gap: 6.666vw;
        margin-bottom: 6.666vw;
    }

    .training_layout_sidebar_category {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 8.888vw;
        gap: 1.111vw;
        border: 1px solid #8BD41F;
        border-radius: 14px;
        padding: 0px 18px;
    }

    .training_layout_sidebar_category.active {
        background-color: #8BD41F;
    }

    .training_layout_sidebar_category.active .training_layout_sidebar_category_left_icon {
        fill: white;
    }

    .training_layout_sidebar_category_left_icon {
        fill: #00C193;
        width: 5vw;
        height: 5vw;
    }

    .training_layout_sidebar_category.active .training_layout_sidebar_category_right_icon {
        fill: white;
    }

    .training_layout_sidebar_category_right_icon {
        fill: #8BD41F;
        width: 4.444vw;
        height: 4.444vw;
    }

    .training_layout_sidebar_category_name {
        white-space: nowrap;
        font-weight: bold;
        font-size: 5vw;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        color: #000000;
    }

    .training_layout_sidebar_lessons {
        display: flex;
        flex-direction: column;
        margin-bottom: 6.666vw;
    }

    .training_layout_sidebar_lesson {
        padding-top: 6.666vw;
        padding-bottom: 6.666vw;
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson.active .training_layout_sidebar_lesson_wrap {
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 100%);
        padding: 3.888vw 3.333vw;
    }

    .training_layout_sidebar_lesson .training_layout_sidebar_lesson_wrap {
        gap: 2.222vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-radius: 3.888vw;
    }

    .training_layout_sidebar_lesson:first-child {
        border-top: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_divider {
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_title {
        font-weight: 900;
        font-size: 3.888vw;
        line-height: 5.833vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #A9AAAE;
        margin-bottom: 2.222vw;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_title {
        color: #27272F;
    }

    .training_layout_sidebar_lesson.type_locked .training_layout_sidebar_lesson_title {
        color: #A9AAAE;
    }

    .training_layout_sidebar_lesson_name {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_name {
        color: black;
    }

    .training_layout_sidebar_lesson.type_quiz .training_layout_sidebar_lesson_name {
        color: #27272F;
    }

    .training_layout_sidebar_lesson_icon {
        width: 8.888vw;
        height: 8.888vw;
        flex: none;
    }

    .training_layout_header_sidebar_wrapper {
        z-index: 1000;
        position: fixed;
        width: 100%;
        top: 0px;
        height: 100vh;
        overflow: auto;
    }

    .training_layout_header_sidebar_wrapper.show {
        display: block !important;
    }

    .training_layout_header_sidebar_wrapper.hide {
        display: none !important;
    }

    .training_layout_header_sidebar_wrapper .training_layout_sidebar {
        width: 100%;
        display: block;
    }

    .training_layout_header_sidebar_wrapper .training_layout_sidebar .training_layout_sidebar_content {
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 10%, transparent 100%);
        margin-bottom: 0px;
    }

    .training_layout_header_sidebar_wrapper .training_layout_sidebar .training_layout_sidebar_content .training_layout_sidebar_content_body {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    #root.position-fixed {
        position: fixed;
        width: 100vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .training_layout_header {
        position: relative;
        overflow: hidden;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #8BD41F;
        color: white;
        padding: 1.319vw 2.5vw;
        height: 4.722vw;
        position: fixed;
        width: 100vw;
        top: 0px;
        z-index: 9;
    }

    .training_layout_header_left {
        display: flex;
        align-items: center;
        z-index: 1;
        visibility: hidden;
    }

    .training_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
        height: 6.666vw;
    }

    .training_layout_header_menu_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_header_title {
        z-index: 0;
        position: absolute;
        left: 0px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: 0.01em;
    }

    .training_layout_header_right {
        z-index: 1;
        width: fit-content;
    }

    .training_layout_header_close_btn {
        color: white;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: none;
        align-items: center;
        gap: 0.416vw;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
    }

    .training_layout_header_close_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_header_profile_container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.486vw;
    }

    .training_layout_header_profile_logout_btn {
        border: 1px solid #DC143C;
        background-color: white;
        border-radius: 8px;
        color: black;
        padding: 0.347vw 0.833vw 0.347vw 1.25vw;
        height: 1.736vw;
        display: flex;
        align-items: center;
        gap: 1.25vw;
        font-size: 0.833vw;
        font-weight: 600;
    }

    .training_layout_header_profile_logout_btn[hidden] {
        display: none !important;
    }

    .training_layout_header_profile_logout_btn svg {
        color: #DC143C;
        font-size: 1.041vw;
    }

    .training_layout_header_profile_avatar {
        width: 2.777vw;
        height: 2.777vw;
        border: 2px solid white;
        border-radius: 9999px;
        cursor: pointer;
    }

    .training_layout_breadcrumb_close_btn {
        color: black;
        white-space: nowrap;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        gap: 0.416vw;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
    }

    .training_layout_breadcrumb_close_btn span {
        display: block;
    }

    .training_layout_breadcrumb_close_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_breadcrumb {
        padding-right: 2.5vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        z-index: 9;
        position: fixed;
        width: 100vw;
        top: 4.722vw;
    }

    .training_layout_breadcrumb_list {
        width: 100%;
        max-width: 100vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 1.597vw;
        padding-bottom: 1.597vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        flex-wrap: wrap;
    }

    .training_layout_breadcrumb_item {
        display: flex;
        align-items: center;
        gap: 0.555vw;
        min-height: 1.527vw;
    }

    .training_layout_breadcrumb_label {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.527vw;
        letter-spacing: -0.01em;
        color: black;
        white-space: wrap;
    }

    .training_layout_breadcrumb_item.active .training_layout_breadcrumb_label {
        color: #DC143C;
    }

    .training_layout_breadcrumb_arrow_icon {
        width: 1.111vw;
        white-space: nowrap;
        height: 1.111vw;
    }

    .training_layout_body {
        padding-top: 11.111vw;
        background-color: #E5E5E5;
    }

    .training_layout_body.position-fixed {
        position: relative !important;
    }

    .training_layout_sidebar {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 2;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_layout_sidebar_content {
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 10%, transparent 100%);
        border-radius: 1.111vw;
        margin-bottom: 5.625vw;
    }

    .training_layout_sidebar_content_header {
        padding: 1.666vw 1.666vw 2.222vw 1.666vw;
        color: white;
    }

    .training_layout_sidebar_overview {
        display: flex;
        align-items: center;
        font-weight: 900;
        font-size: 0.972vw;
        color: black;
        text-transform: uppercase;
        line-height: 1.458vw;
        margin-bottom: 1.111vw;
    }

    .training_layout_sidebar_overview_icon {
        flex: none;
        width: 3.333vw;
        height: 3.333vw;
        margin-right: 1.666vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .training_layout_sidebar_overview_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_sidebar_overview_icon.icon_menu img {
        width: 2.222vw;
        height: 2.222vw;
    }

    .training_layout_sidebar_overview_left_lessons {
        white-space: nowrap;
        margin-right: 1.111vw;
    }

    .training_layout_sidebar_overview_points {
        white-space: nowrap;
    }

    .training_layout_sidebar_overview_divider {
        height: 2.222vw;
        border-right: 2px solid #FFFFFF;
        opacity: 0.2;
        margin-right: 1.111vw;
    }

    .training_layout_sidebar_close_btn {
        background-color: transparent;
        border: 0px;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .training_layout_sidebar_close_icon {
        width: 3.333vw;
        height: 3.333vw;
    }


    .training_layout_sidebar_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        text-shadow: 0px 0.138vw 0.833vw #0b0e1a14;
        margin-bottom: 1.666vw;
        color: black;
    }

    .training_layout_sidebar_progress_bar {
        display: flex;
        align-items: center;
        height: 0.555vw;
        gap: 0.416vw;
    }

    .training_layout_sidebar_progress_item {
        height: 100%;
        background: #FFFFFF;
        width: 100%;
        border-radius: 2px;
    }

    .training_layout_sidebar_progress_item.active {
        opacity: 1;
        background-color: black;
    }

    .training_layout_sidebar_content_body {
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 1.111vw;
    }

    .training_layout_sidebar_categories {
        display: flex;
        flex-direction: column;
        gap: 1.666vw;
        margin-bottom: 1.666vw;
    }

    .training_layout_sidebar_category {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.222vw;
        gap: 0.277vw;
        border: 1px solid #8BD41F;
        border-radius: 14px;
        padding: 0px 18px;
    }

    .training_layout_sidebar_category.active {
        background-color: #8BD41F;
    }

    .training_layout_sidebar_category.active .training_layout_sidebar_category_left_icon {
        fill: white;
    }

    .training_layout_sidebar_category_left_icon {
        fill: #00C193;
        width: 1.25vw;
        height: 1.25vw;
    }

    .training_layout_sidebar_category.active .training_layout_sidebar_category_right_icon {
        fill: white;
    }

    .training_layout_sidebar_category_right_icon {
        fill: #8BD41F;
        width: 1.111vw;
        height: 1.111vw;
    }

    .training_layout_sidebar_category_name {
        white-space: nowrap;
        font-weight: bold;
        font-size: 1.25vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #000000;
    }

    .training_layout_sidebar_lessons {
        display: flex;
        flex-direction: column;
        margin-bottom: 2.222vw;
    }

    .training_layout_sidebar_lesson {
        padding-top: 1.666vw;
        padding-bottom: 1.666vw;
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson.active .training_layout_sidebar_lesson_wrap {
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 100%);
        padding: 0.972vw 0.833vw;
    }

    .training_layout_sidebar_lesson .training_layout_sidebar_lesson_wrap {
        gap: 0.555vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-radius: 0.972vw;
    }

    .training_layout_sidebar_lesson:first-child {
        border-top: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_divider {
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #A9AAAE;
        margin-bottom: 0.555vw;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_title {
        color: #27272F;
    }

    .training_layout_sidebar_lesson.type_locked .training_layout_sidebar_lesson_title {
        color: #A9AAAE;
    }

    .training_layout_sidebar_lesson_name {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_name {
        color: black;
    }

    .training_layout_sidebar_lesson.type_quiz .training_layout_sidebar_lesson_name {
        color: #27272F;
    }

    .training_layout_sidebar_lesson_icon {
        width: 2.222vw;
        height: 2.222vw;
        flex: none;
    }

    .training_layout_header_sidebar_wrapper {
        display: none !important;
    }

    #root.position-fixed {
        position: fixed;
        width: 100vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .training_layout_header {
        position: relative;
        overflow: hidden;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #8BD41F;
        color: white;
        padding: 1.319vw 2.5vw;
        height: 4.722vw;
        position: fixed;
        width: 100vw;
        top: 0px;
        z-index: 9;
    }

    .training_layout_header_left {
        display: flex;
        align-items: center;
        z-index: 1;
        visibility: hidden;
    }

    .training_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
        height: 6.666vw;
    }

    .training_layout_header_menu_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_header_title {
        z-index: 0;
        position: absolute;
        left: 0px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: 0.01em;
    }

    .training_layout_header_right {
        z-index: 1;
        width: fit-content;
    }

    .training_layout_header_close_btn {
        color: white;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: none;
        align-items: center;
        gap: 0.416vw;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
    }

    .training_layout_header_close_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_header_profile_container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.486vw;
    }

    .training_layout_header_profile_logout_btn {
        border: 1px solid #DC143C;
        background-color: white;
        border-radius: 8px;
        color: black;
        padding: 0.347vw 0.833vw 0.347vw 1.25vw;
        height: 1.736vw;
        display: flex;
        align-items: center;
        gap: 1.25vw;
        font-size: 0.833vw;
        font-weight: 600;
    }

    .training_layout_header_profile_logout_btn[hidden] {
        display: none !important;
    }

    .training_layout_header_profile_logout_btn svg {
        color: #DC143C;
        font-size: 1.041vw;
    }

    .training_layout_header_profile_avatar {
        width: 2.777vw;
        height: 2.777vw;
        border: 2px solid white;
        border-radius: 9999px;
        cursor: pointer;
    }

    .training_layout_breadcrumb_close_btn {
        color: black;
        white-space: nowrap;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        gap: 0.416vw;
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.388vw;
        letter-spacing: -0.01em;
    }

    .training_layout_breadcrumb_close_btn span {
        display: block;
    }

    .training_layout_breadcrumb_close_icon {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_breadcrumb {
        padding-right: 2.5vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        z-index: 9;
        position: fixed;
        width: 100vw;
        top: 4.722vw;
    }

    .training_layout_breadcrumb_list {
        width: 100%;
        max-width: 100vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 1.597vw;
        padding-bottom: 1.597vw;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        flex-wrap: wrap;
    }

    .training_layout_breadcrumb_item {
        display: flex;
        align-items: center;
        gap: 0.555vw;
        min-height: 1.527vw;
    }

    .training_layout_breadcrumb_label {
        font-weight: 700;
        font-size: 0.972vw;
        line-height: 1.527vw;
        letter-spacing: -0.01em;
        color: black;
        white-space: wrap;
    }

    .training_layout_breadcrumb_item.active .training_layout_breadcrumb_label {
        color: #DC143C;
    }

    .training_layout_breadcrumb_arrow_icon {
        width: 1.111vw;
        white-space: nowrap;
        height: 1.111vw;
    }

    .training_layout_body {
        padding-top: 11.111vw;
        background-color: #E5E5E5;
    }

    .training_layout_body.position-fixed {
        position: relative !important;
    }

    .training_layout_sidebar {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_layout_sidebar_content {
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 10%, transparent 100%);
        border-radius: 1.111vw;
        margin-bottom: 5.625vw;
    }

    .training_layout_sidebar_content_header {
        padding: 1.666vw 1.666vw 2.222vw 1.666vw;
        color: white;
    }

    .training_layout_sidebar_overview {
        display: flex;
        align-items: center;
        font-weight: 900;
        font-size: 0.972vw;
        color: black;
        text-transform: uppercase;
        line-height: 1.458vw;
        margin-bottom: 1.111vw;
    }

    .training_layout_sidebar_overview_icon {
        flex: none;
        width: 3.333vw;
        height: 3.333vw;
        margin-right: 1.666vw;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .training_layout_sidebar_overview_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_layout_sidebar_overview_icon.icon_menu img {
        width: 2.222vw;
        height: 2.222vw;
    }

    .training_layout_sidebar_overview_left_lessons {
        white-space: nowrap;
        margin-right: 1.111vw;
    }

    .training_layout_sidebar_overview_points {
        white-space: nowrap;
    }

    .training_layout_sidebar_overview_divider {
        height: 2.222vw;
        border-right: 2px solid #FFFFFF;
        opacity: 0.2;
        margin-right: 1.111vw;
    }

    .training_layout_sidebar_close_btn {
        background-color: transparent;
        border: 0px;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .training_layout_sidebar_close_icon {
        width: 3.333vw;
        height: 3.333vw;
    }


    .training_layout_sidebar_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        text-shadow: 0px 0.138vw 0.833vw #0b0e1a14;
        margin-bottom: 1.666vw;
        color: black;
    }

    .training_layout_sidebar_progress_bar {
        display: flex;
        align-items: center;
        height: 0.555vw;
        gap: 0.416vw;
    }

    .training_layout_sidebar_progress_item {
        height: 100%;
        background: #FFFFFF;
        width: 100%;
        border-radius: 2px;
    }

    .training_layout_sidebar_progress_item.active {
        opacity: 1;
        background-color: black;
    }

    .training_layout_sidebar_content_body {
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 1.111vw;
    }

    .training_layout_sidebar_categories {
        display: flex;
        flex-direction: column;
        gap: 1.666vw;
        margin-bottom: 1.666vw;
    }

    .training_layout_sidebar_category {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.222vw;
        gap: 0.277vw;
        border: 1px solid #8BD41F;
        border-radius: 14px;
        padding: 0px 18px;
    }

    .training_layout_sidebar_category.active {
        background-color: #8BD41F;
    }

    .training_layout_sidebar_category.active .training_layout_sidebar_category_left_icon {
        fill: white;
    }

    .training_layout_sidebar_category_left_icon {
        fill: #00C193;
        width: 1.25vw;
        height: 1.25vw;
    }

    .training_layout_sidebar_category.active .training_layout_sidebar_category_right_icon {
        fill: white;
    }

    .training_layout_sidebar_category_right_icon {
        fill: #8BD41F;
        width: 1.111vw;
        height: 1.111vw;
    }

    .training_layout_sidebar_category_name {
        white-space: nowrap;
        font-weight: bold;
        font-size: 1.25vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #000000;
    }

    .training_layout_sidebar_lessons {
        display: flex;
        flex-direction: column;
        margin-bottom: 2.222vw;
    }

    .training_layout_sidebar_lesson {
        padding-top: 1.666vw;
        padding-bottom: 1.666vw;
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson.active .training_layout_sidebar_lesson_wrap {
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 100%);
        padding: 0.972vw 0.833vw;
    }

    .training_layout_sidebar_lesson .training_layout_sidebar_lesson_wrap {
        gap: 0.555vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-radius: 0.972vw;
    }

    .training_layout_sidebar_lesson:first-child {
        border-top: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_divider {
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #A9AAAE;
        margin-bottom: 0.555vw;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_title {
        color: #27272F;
    }

    .training_layout_sidebar_lesson.type_locked .training_layout_sidebar_lesson_title {
        color: #A9AAAE;
    }

    .training_layout_sidebar_lesson_name {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_name {
        color: black;
    }

    .training_layout_sidebar_lesson.type_quiz .training_layout_sidebar_lesson_name {
        color: #27272F;
    }

    .training_layout_sidebar_lesson_icon {
        width: 2.222vw;
        height: 2.222vw;
        flex: none;
    }

    .training_layout_header_sidebar_wrapper {
        display: none !important;
    }

    #root.position-fixed {
        position: fixed;
        width: 100vw;
    }
}

@media only screen and (min-width: 1441px) {
    .training_layout_header {
        position: relative;
        overflow: hidden;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #8BD41F;
        color: white;
        padding: 19px 36px;
        height: 68px;
        position: fixed;
        width: 100vw;
        top: 0px;
        z-index: 9;
    }

    .training_layout_header_left {
        display: flex;
        align-items: center;
        z-index: 1;
        visibility: hidden;
    }

    .training_layout_header_menu_btn {
        background-color: transparent;
        border: 0;
        outline: none;
        height: 6.666vw;
    }

    .training_layout_header_menu_icon {
        width: 24px;
        height: 24px;
    }

    .training_layout_header_title {
        z-index: 0;
        position: absolute;
        left: 0px;
        text-align: center;
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.01em;
    }

    .training_layout_header_right {
        z-index: 1;
        width: fit-content;
    }

    .training_layout_header_close_btn {
        color: white;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: none;
        align-items: center;
        gap: 6px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
    }

    .training_layout_header_close_icon {
        width: 24px;
        height: 24px;
    }

    .training_layout_header_profile_container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
    }

    .training_layout_header_profile_logout_btn {
        border: 1px solid #DC143C;
        background-color: white;
        border-radius: 8px;
        color: black;
        padding: 5px 12px 5px 18px;
        height: 25px;
        display: flex;
        align-items: center;
        gap: 18px;
        font-size: 12px;
        font-weight: 600;
    }

    .training_layout_header_profile_logout_btn[hidden] {
        display: none !important;
    }

    .training_layout_header_profile_logout_btn svg {
        color: #DC143C;
        font-size: 15px;
    }

    .training_layout_header_profile_avatar {
        width: 40px;
        height: 40px;
        border: 2px solid white;
        border-radius: 9999px;
        cursor: pointer;
    }

    .training_layout_breadcrumb_close_btn {
        color: black;
        white-space: nowrap;
        background-color: transparent;
        border: 0px;
        outline: none;
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.01em;
    }

    .training_layout_breadcrumb_close_btn span {
        display: block;
    }

    .training_layout_breadcrumb_close_icon {
        width: 24px;
        height: 24px;
    }

    .training_layout_breadcrumb {
        padding-right: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        z-index: 9;
        position: fixed;
        width: 100vw;
        top: 68px;
    }

    .training_layout_breadcrumb_list {
        width: 100%;
        max-width: 100vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        padding-top: 23px;
        padding-bottom: 23px;
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
    }

    .training_layout_breadcrumb_item {
        display: flex;
        align-items: center;
        gap: 8px;
        min-height: 22px;
    }

    .training_layout_breadcrumb_label {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: black;
        white-space: wrap;
    }

    .training_layout_breadcrumb_item.active .training_layout_breadcrumb_label {
        color: #DC143C;
    }

    .training_layout_breadcrumb_arrow_icon {
        width: 16px;
        white-space: nowrap;
        height: 16px;
    }

    .training_layout_body {
        padding-top: 160px;
        background-color: #E5E5E5;
    }

    .training_layout_body.position-fixed {
        position: relative !important;
    }

    .training_layout_sidebar {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_layout_sidebar_content {
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 10%, transparent 100%);
        border-radius: 16px;
        margin-bottom: 81px;
    }

    .training_layout_sidebar_content_header {
        padding: 24px 24px 32px 24px;
        color: white;
    }

    .training_layout_sidebar_overview {
        display: flex;
        align-items: center;
        font-weight: 900;
        font-size: 14px;
        color: black;
        text-transform: uppercase;
        line-height: 21px;
        margin-bottom: 16px;
    }

    .training_layout_sidebar_overview_icon {
        flex: none;
        width: 48px;
        height: 48px;
        margin-right: 24px;
        border-radius: 9999px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .training_layout_sidebar_overview_icon img {
        width: 24px;
        height: 24px;
    }

    .training_layout_sidebar_overview_icon.icon_menu img {
        width: 32px;
        height: 32px;
    }

    .training_layout_sidebar_overview_left_lessons {
        white-space: nowrap;
        margin-right: 16px;
    }

    .training_layout_sidebar_overview_points {
        white-space: nowrap;
    }

    .training_layout_sidebar_overview_divider {
        height: 32px;
        border-right: 2px solid #FFFFFF;
        opacity: 0.2;
        margin-right: 16px;
    }

    .training_layout_sidebar_close_btn {
        background-color: transparent;
        border: 0px;
        outline: none;
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .training_layout_sidebar_close_icon {
        width: 48px;
        height: 48px;
    }


    .training_layout_sidebar_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 31px;
        line-height: 40px;
        letter-spacing: -0.02em;
        text-shadow: 0px 2px 12px #0b0e1a14;
        margin-bottom: 24px;
        color: black;
    }

    .training_layout_sidebar_progress_bar {
        display: flex;
        align-items: center;
        height: 8px;
        gap: 6px;
    }

    .training_layout_sidebar_progress_item {
        height: 100%;
        background: #FFFFFF;
        width: 100%;
        border-radius: 2px;
    }

    .training_layout_sidebar_progress_item.active {
        opacity: 1;
        background-color: black;
    }

    .training_layout_sidebar_content_body {
        padding: 32px 28px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_layout_sidebar_categories {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 24px;
    }

    .training_layout_sidebar_category {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;
        gap: 4px;
        border: 1px solid #8BD41F;
        border-radius: 14px;
        padding: 0px 18px;
    }

    .training_layout_sidebar_category.active {
        background-color: #8BD41F;
    }

    .training_layout_sidebar_category.active .training_layout_sidebar_category_left_icon {
        fill: white;
    }

    .training_layout_sidebar_category_left_icon {
        fill: #00C193;
        width: 18px;
        height: 18px;
    }

    .training_layout_sidebar_category.active .training_layout_sidebar_category_right_icon {
        fill: white;
    }

    .training_layout_sidebar_category_right_icon {
        fill: #8BD41F;
        width: 16px;
        height: 16px;
    }

    .training_layout_sidebar_category_name {
        white-space: nowrap;
        font-weight: bold;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #000000;
    }

    .training_layout_sidebar_lessons {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
    }

    .training_layout_sidebar_lesson {
        padding-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson.active .training_layout_sidebar_lesson_wrap {
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 100%);
        ;
        padding: 14px 12px;
    }

    .training_layout_sidebar_lesson .training_layout_sidebar_lesson_wrap {
        gap: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-radius: 14px
    }

    .training_layout_sidebar_lesson:first-child {
        border-top: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_divider {
        border-bottom: 1px solid #EEEEF0;
    }

    .training_layout_sidebar_lesson_title {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #A9AAAE;
        margin-bottom: 8px;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_title {
        color: #27272F;
    }

    .training_layout_sidebar_lesson.type_locked .training_layout_sidebar_lesson_title {
        color: #A9AAAE;
    }

    .training_layout_sidebar_lesson_name {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_layout_sidebar_lesson.type_ongoing .training_layout_sidebar_lesson_name {
        color: black;
    }

    .training_layout_sidebar_lesson.type_quiz .training_layout_sidebar_lesson_name {
        color: #27272F;
    }

    .training_layout_sidebar_lesson_icon {
        width: 32px;
        height: 32px;
        flex: none;
    }

    .training_layout_header_sidebar_wrapper {
        display: none !important;
    }

    #root.position-fixed {
        position: fixed;
        width: 100vw;
    }
}