@media only screen and (max-width: 480px) {
    .training_course_dashboard {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_dashboard_content {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 6.666vw 4.444vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_dashboard_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 6.666vw;
        line-height: 8.611vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 6.666vw;
    }

    .training_course_dashboard_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 7.222vw;
        letter-spacing: -0.01em;
        color: #5B5B62;

        margin-bottom: 6.666vw;
    }

    .training_course_dashboard_lessons {
        border-top: 1px solid #EEEEF0;
        padding-top: 6.666vw;
        display: flex;
        flex-direction: column;
        gap: 6.666vw;
    }

    .training_course_dashboard_lesson {
        margin-bottom: 6.666vw;
        cursor: pointer;
    }

    .training_course_dashboard_lesson_divider {
        border-bottom: 1px solid #EEEEF0;
    }

    .training_course_dashboard_lesson_title {
        font-weight: 900;
        font-size: 3.333vw;
        line-height: 5vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #27272F;
        margin-bottom: 1.944vw;
    }

    .training_course_dashboard_lesson.type_ongoing .training_course_dashboard_lesson_title {
        color: #27272F;
    }

    .training_course_dashboard_lesson.type_locked .training_course_dashboard_lesson_title {
        color: #A9AAAE;
    }

    .training_course_dashboard_lesson_body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 3.888vw;
    }

    .training_course_dashboard_lesson_name {
        font-weight: 500;
        font-size: 5.555vw;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_course_dashboard_lesson.type_ongoing .training_course_dashboard_lesson_name {
        color: #000000;
    }

    .training_course_dashboard_lesson.type_quiz .training_course_dashboard_lesson_name {
        color: #27272F;
    }

    .training_course_dashboard_lesson_icon {
        width: 8.888vw;
        height: 8.888vw;
        flex: none;
    }

    .training_course_dashboard_lesson_progress {
        margin-top: 6.666vw;
    }

    .course_item_action_btn {
        font-weight: 700;
        font-size: 3.888vw;
        line-height: 6.111vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        gap: 1.111vw;
        flex: none;
        display: flex;
        padding: 1.666vw 3.333vw;
        color: white;
        align-items: center;
        border-radius: 9999px;
    }

    .course_item_action_btn img {
        width: 6.666vw;
        height: 6.666vw;
    }

    .course_item_action_btn.go_btn {
        background-color: #055CF5;
    }

    .course_item_action_btn.inprogress_btn {
        background-color: #055CF5;
    }

    .course_item_action_btn.inprogress_btn span {
        white-space: nowrap;
    }

    .course_item_action_btn.done_btn {
        background-color: #41AD49;
    }

    .course_item_action_btn.locked_btn {
        background-color: #A9AAAE;
    }

    .course_item_action_btn.hold_btn {
        background-color: #055CF5;
    }

    .course_item_action_btn.retry_btn {
        background-color: #FF4600;
    }

    .course_item_action_btn.review_btn {
        background-color: #41AD49;
    }

    .course_item_divider {
        height: 1px;
        margin: auto;
        background-color: #EEEEF0;
    }

    .course_item_container {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 2.222vw;
        padding-top: 5.555vw;
        padding-bottom: 5.555vw;
    }

    .course_item_index {
        flex: none;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        font-family: Azo Sans, sans-serif !important;
        font-weight: 900;
        border-radius: 9999px;
        letter-spacing: -0.02em;
        justify-content: center;
        width: 14.444vw;
        height: 14.444vw;
        font-size: 8.888vw;
        line-height: 8.888vw;
    }

    .course_item_right_container {
        gap: 4.444vw;
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: row;
    }

    .course_item_text_container {
        flex-grow: 1;
    }

    .course_item_top_title {
        font-weight: 900;
        font-size: 3.333vw;
        line-height: 5vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #41AD49;
    }

    .course_item_title {
        color: #0D0D16;
        font-size: 5.555vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        letter-spacing: -0.01em;
        line-height: 8.333vw;
    }

    .course_item_description {
        display: none;
        color: #5B5B62;
        font-size: 4.444vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        line-height: 7.111vw;
        letter-spacing: -0.01em;
    }

    .course_item_icon {
        width: 14.444vw;
        height: 14.444vw;
        max-width: 100%;
        max-height: 100%;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }

    .course_item_icon img {
        width: 7.222vw;
        height: 7.222vw;
    }

    .course_item_checked_icon {
        width: 8.194vw;
        height: 7.083vw;
        margin: auto;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .training_course_dashboard {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_dashboard_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_dashboard_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 1.666vw;
    }

    .training_course_dashboard_description {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.222vw;
    }

    .training_course_dashboard_lessons {
        border-top: 1px solid #EEEEF0;
        padding-top: 1.666vw;
        display: flex;
        flex-direction: column;
        gap: 1.666vw;
    }

    .training_course_dashboard_lesson {
        margin-bottom: 1.666vw;
        cursor: pointer;
    }

    .training_course_dashboard_lesson_divider {
        border-bottom: 1px solid #EEEEF0;
    }

    .training_course_dashboard_lesson_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #A9AAAE;
        margin-bottom: 0.555vw;
    }

    .training_course_dashboard_lesson.type_ongoing .training_course_dashboard_lesson_title {
        color: #27272F;
    }

    .training_course_dashboard_lesson.type_locked .training_course_dashboard_lesson_title {
        color: #A9AAAE;
    }

    .training_course_dashboard_lesson_body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.555vw;
    }

    .training_course_dashboard_lesson_name {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_course_dashboard_lesson.type_ongoing .training_course_dashboard_lesson_name {
        color: #000000;
    }

    .training_course_dashboard_lesson.type_quiz .training_course_dashboard_lesson_name {
        color: #27272F;
    }

    .training_course_dashboard_lesson_icon {
        width: 2.222vw;
        height: 2.222vw;
        flex: none;
    }

    .training_course_dashboard_lesson_progress {
        margin-top: 1.666vw;
    }

    .course_item_action_btn {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        gap: 0.277vw;
        flex: none;
        display: flex;
        padding: 0.416vw 0.833vw;
        color: white;
        align-items: center;
        border-radius: 9999px;
    }

    .course_item_action_btn img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .course_item_action_btn.go_btn {
        background-color: #055CF5;
    }

    .course_item_action_btn.inprogress_btn {
        background-color: #055CF5;
    }

    .course_item_action_btn.inprogress_btn span {
        white-space: nowrap;
    }

    .course_item_action_btn.done_btn {
        background-color: #41AD49;
    }

    .course_item_action_btn.locked_btn {
        background-color: #A9AAAE;
    }

    .course_item_action_btn.hold_btn {
        background-color: #055CF5;
    }

    .course_item_action_btn.retry_btn {
        background-color: #FF4600;
    }

    .course_item_action_btn.review_btn {
        background-color: #41AD49;
    }

    .course_item_divider {
        height: 1px;
        margin: auto;
        background-color: #EEEEF0;
    }

    .course_item_container {
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        gap: 1.111vw;
        padding-top: 1.388vw;
        padding-bottom: 1.388vw;
    }

    .course_item_index {
        flex: none;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        font-family: Azo Sans, sans-serif !important;
        font-weight: 900;
        border-radius: 9999px;
        letter-spacing: -0.02em;
        justify-content: center;
        width: 4.444vw;
        height: 4.444vw;
        font-size: 2.5vw;
        line-height: 2.5vw;
    }

    .course_item_right_container {
        gap: 1.111vw;
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: row;
    }

    .course_item_text_container {
        flex-grow: 1;
    }

    .course_item_top_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #41AD49;
    }

    .course_item_title {
        color: #0D0D16;
        font-size: 1.388vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        letter-spacing: -0.01em;
        line-height: 2.083vw;
    }

    .course_item_description {
        display: block;
        color: #5B5B62;
        font-size: 1.111vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        line-height: 1.777vw;
        letter-spacing: -0.01em;
    }

    .course_item_icon {
        width: 4.444vw;
        height: 4.444vw;
        max-width: 100%;
        max-height: 100%;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }

    .course_item_icon img {
        width: 2.222vw;
        height: 2.222vw;
    }

    .course_item_checked_icon {
        width: 2.048vw;
        height: 1.77vw;
        margin: auto;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .training_course_dashboard {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_dashboard_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_dashboard_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 1.666vw;
    }

    .training_course_dashboard_description {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.222vw;
    }

    .training_course_dashboard_lessons {
        border-top: 1px solid #EEEEF0;
        padding-top: 1.666vw;
        display: flex;
        flex-direction: column;
        gap: 1.666vw;
    }

    .training_course_dashboard_lesson {
        margin-bottom: 1.666vw;
        cursor: pointer;
    }

    .training_course_dashboard_lesson_divider {
        border-bottom: 1px solid #EEEEF0;
    }

    .training_course_dashboard_lesson_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #A9AAAE;
        margin-bottom: 0.555vw;
    }

    .training_course_dashboard_lesson.type_ongoing .training_course_dashboard_lesson_title {
        color: #27272F;
    }

    .training_course_dashboard_lesson.type_locked .training_course_dashboard_lesson_title {
        color: #A9AAAE;
    }

    .training_course_dashboard_lesson_body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.555vw;
    }

    .training_course_dashboard_lesson_name {
        font-weight: 500;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_course_dashboard_lesson.type_ongoing .training_course_dashboard_lesson_name {
        color: #000000;
    }

    .training_course_dashboard_lesson.type_quiz .training_course_dashboard_lesson_name {
        color: #27272F;
    }

    .training_course_dashboard_lesson_icon {
        width: 2.222vw;
        height: 2.222vw;
        flex: none;
    }

    .training_course_dashboard_lesson_progress {
        margin-top: 1.666vw;
    }

    .course_item_action_btn {
        font-weight: 700;
        font-size: 1.111vw;
        line-height: 1.527vw;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        gap: 0.277vw;
        flex: none;
        display: flex;
        padding: 0.416vw 0.833vw;
        color: white;
        align-items: center;
        border-radius: 9999px;
    }

    .course_item_action_btn img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .course_item_action_btn.go_btn {
        background-color: #055CF5;
    }

    .course_item_action_btn.inprogress_btn {
        background-color: #055CF5;
    }

    .course_item_action_btn.inprogress_btn span {
        white-space: nowrap;
    }

    .course_item_action_btn.done_btn {
        background-color: #41AD49;
    }

    .course_item_action_btn.locked_btn {
        background-color: #A9AAAE;
    }

    .course_item_action_btn.hold_btn {
        background-color: #055CF5;
    }

    .course_item_action_btn.retry_btn {
        background-color: #FF4600;
    }

    .course_item_action_btn.review_btn {
        background-color: #41AD49;
    }

    .course_item_divider {
        height: 1px;
        margin: auto;
        background-color: #EEEEF0;
    }

    .course_item_container {
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        gap: 1.111vw;
        padding-top: 1.388vw;
        padding-bottom: 1.388vw;
    }

    .course_item_index {
        flex: none;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        font-family: Azo Sans, sans-serif !important;
        font-weight: 900;
        border-radius: 9999px;
        letter-spacing: -0.02em;
        justify-content: center;
        width: 4.444vw;
        height: 4.444vw;
        font-size: 2.5vw;
        line-height: 2.5vw;
    }

    .course_item_right_container {
        gap: 1.111vw;
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: row;
    }

    .course_item_text_container {
        flex-grow: 1;
    }

    .course_item_top_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #41AD49;
    }

    .course_item_title {
        color: #0D0D16;
        font-size: 1.388vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        letter-spacing: -0.01em;
        line-height: 2.083vw;
    }

    .course_item_description {
        display: block;
        color: #5B5B62;
        font-size: 1.111vw;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        line-height: 1.777vw;
        letter-spacing: -0.01em;
    }

    .course_item_icon {
        width: 4.444vw;
        height: 4.444vw;
        max-width: 100%;
        max-height: 100%;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }

    .course_item_icon img {
        width: 2.222vw;
        height: 2.222vw;
    }

    .course_item_checked_icon {
        width: 2.048vw;
        height: 1.77vw;
        margin: auto;
    }
}

@media only screen and (min-width: 1441px) {
    .training_course_dashboard {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_dashboard_content {
        width: 100%;
        margin-left: 0px;
        padding: 40px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_dashboard_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 31px;
        line-height: 40px;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 24px;
    }

    .training_course_dashboard_description {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 32px;
    }


    .training_course_dashboard_lessons {
        border-top: 1px solid #EEEEF0;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .training_course_dashboard_lesson {
        margin-bottom: 24px;
        cursor: pointer;
    }

    .training_course_dashboard_lesson_divider {
        border-bottom: 1px solid #EEEEF0;
    }

    .training_course_dashboard_lesson_title {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #A9AAAE;
        margin-bottom: 8px;
    }

    .training_course_dashboard_lesson.type_ongoing .training_course_dashboard_lesson_title {
        color: #27272F;
    }

    .training_course_dashboard_lesson.type_locked .training_course_dashboard_lesson_title {
        color: #A9AAAE;
    }

    .training_course_dashboard_lesson_body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
    }

    .training_course_dashboard_lesson_name {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #5B5B62;
        flex-grow: 1;
    }

    .training_course_dashboard_lesson.type_ongoing .training_course_dashboard_lesson_name {
        color: #000000;
    }

    .training_course_dashboard_lesson.type_quiz .training_course_dashboard_lesson_name {
        color: #27272F;
    }

    .training_course_dashboard_lesson_icon {
        width: 32px;
        height: 32px;
        flex: none;
    }

    .training_course_dashboard_lesson_progress {
        margin-top: 24px;
    }

    .course_item_action_btn {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em;
        gap: 4px;
        flex: none;
        display: flex;
        padding: 6px 12px;
        color: white;
        align-items: center;
        border-radius: 9999px;
    }

    .course_item_action_btn img {
        width: 24px;
        height: 24px;
    }

    .course_item_action_btn.go_btn {
        background-color: #055CF5;
    }

    .course_item_action_btn.inprogress_btn {
        background-color: #055CF5;
    }

    .course_item_action_btn.inprogress_btn span {
        white-space: nowrap;
    }

    .course_item_action_btn.done_btn {
        background-color: #41AD49;
    }

    .course_item_action_btn.locked_btn {
        background-color: #A9AAAE;
    }

    .course_item_action_btn.hold_btn {
        background-color: #055CF5;
    }

    .course_item_action_btn.retry_btn {
        background-color: #FF4600;
    }

    .course_item_action_btn.review_btn {
        background-color: #41AD49;
    }

    .course_item_divider {
        height: 1px;
        margin: auto;
        background-color: #EEEEF0;
    }

    .course_item_container {
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        gap: 16px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .course_item_index {
        flex: none;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        font-family: Azo Sans, sans-serif !important;
        font-weight: 900;
        border-radius: 9999px;
        letter-spacing: -0.02em;
        justify-content: center;
        width: 64px;
        height: 64px;
        font-size: 36px;
        line-height: 36px;
    }

    .course_item_right_container {
        gap: 16px;
        display: flex;
        flex-grow: 1;
        align-items: center;
        flex-direction: row;
    }

    .course_item_text_container {
        flex-grow: 1;
    }

    .course_item_top_title {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #41AD49;
    }

    .course_item_title {
        color: #0D0D16;
        font-size: 20px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 700;
        letter-spacing: -0.01em;
        line-height: 30px;
    }

    .course_item_description {
        display: block;
        color: #5B5B62;
        font-size: 16px;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        font-weight: 400;
        line-height: 25.6px;
        letter-spacing: -0.01em;
    }

    .course_item_icon {
        width: 64px;
        height: 64px;
        max-width: 100%;
        max-height: 100%;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }

    .course_item_icon img {
        width: 32px;
        height: 32px;
    }

    .course_item_checked_icon {
        width: 29.5px;
        height: 25.5px;
        margin: auto;
    }
}