@media only screen and (max-width: 480px) {
    .training_course_lesson_check_in {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_check_in_content {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 6.666vw 4.444vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 0px;
    }

    .training_course_lesson_check_in_title {
        font-weight: 900;
        font-size: 3.333vw;
        line-height: 5vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #DC143C;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_check_in_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 6.666vw;
    }

    .training_course_lesson_check_in_question {
        font-weight: 700;
        font-size: 5.555vw;
        line-height: 8.333vw;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 6.666vw;
    }

    .training_course_lesson_check_in_actions {
        display: flex;
        gap: 4.444vw;
        margin-bottom: 6.666vw;
    }

    .training_course_lesson_check_in_action_btn {
        width: 100%;
        background-color: #E5E5E5;
        border-radius: 8px;
        height: 17.777vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 5vw;
        line-height: 6.111vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: black;
        outline: none;
        border: 0px;
    }

    .training_course_lesson_check_in_action_btn.active {
        background-color: #8BD41F;
        color: white;
    }

    .training_course_lesson_check_in_action_btn.training_course_lesson_check_in_action_btn_true.active.done {
        background-color: #8BD41F;
    }

    .training_course_lesson_check_in_action_btn.training_course_lesson_check_in_action_btn_false.active.done {
        background-color: #DC143C;
    }

    .training_course_lesson_check_in_footer {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 7.222vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_lesson_check_in_footer.active {
        display: block;
    }

    .training_course_lesson_check_in_footer a {
        color: #5B5B62;
        white-space: nowrap;
    }

    .training_course_lesson_check_in_good_job_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_course_lesson_check_in_good_job_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_course_lesson_check_in_good_job_modal_body {
        height: fit-content;
        margin-top: auto;
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: 100;
    }

    .training_course_lesson_check_in_good_job_modal_close_btn {
        position: absolute;
        right: 3.888vw;
        top: 0px;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        width: 11.111vw;
        height: 11.111vw;
    }

    .training_course_lesson_check_in_good_job_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .training_course_lesson_check_in_good_job {
        display: none;
    }

    .training_course_lesson_check_in_good_job_modal .training_course_lesson_check_in_good_job {
        padding: 11.111vw 6.666vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        background: white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    .training_course_lesson_check_in_good_job_icon {
        width: 18.055vw;
        height: 18.055vw;
        margin-bottom: 4.166vw;
    }

    .training_course_lesson_check_in_good_job_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 7.777vw;
        line-height: 10vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #8BD41F;
        margin-bottom: 4.444vw;
    }

    .training_course_lesson_check_in_good_job_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 6.666vw;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 8.888vw;
    }

    .training_course_lesson_check_in_good_job_btn {
        width: 100% !important;
    }

    .training_course_lesson_check_in_incorrect_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_course_lesson_check_in_incorrect_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_course_lesson_check_in_incorrect_modal_body {
        height: fit-content;
        margin-top: auto;
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: 100;
    }

    .training_course_lesson_check_in_incorrect_modal_close_btn {
        position: absolute;
        right: 3.888vw;
        top: -6.666vw;
        border: none;
        background-color: transparent;
        width: 11.111vw;
        height: 11.111vw;
    }

    .training_course_lesson_check_in_incorrect_modal_close_btn img {
        width: 100%;
        height: 100%;
    }

    .training_course_lesson_check_in_incorrect {
        display: none;
    }

    .training_course_lesson_check_in_incorrect_modal .training_course_lesson_check_in_incorrect {
        padding: 11.111vw 6.666vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        background: white;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    .training_course_lesson_check_in_incorrect_icon {
        width: 18.055vw;
        height: 18.055vw;
        margin-bottom: 4.166vw;
    }

    .training_course_lesson_check_in_incorrect_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 7.777vw;
        line-height: 10vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #DC143C;
        margin-bottom: 4.444vw;
    }

    .training_course_lesson_check_in_incorrect_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 6.666vw;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 8.888vw;
    }

    .training_course_lesson_check_in_incorrect_btn {
        width: 100% !important;
    }

    .training_course_lesson_check_in_incorrect {
        display: none;
    }

    .training_course_lesson_check_in_right {
        display: none;
    }

    .training_course_lesson_check_in_complete_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_course_lesson_check_in_complete_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_course_lesson_check_in_complete_modal_body {
        height: fit-content;
        position: absolute;
        top: 28.333vw;
        z-index: 100;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100vw - 17.777vw);
    }

    .training_course_lesson_check_in_complete_modal_content {
        width: 100%;
        padding: 8.333vw 7.777vw 6.666vw 7.222vw;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_course_lesson_check_in_complete_modal_icon {
        width: 33.888vw;
        height: 26.305vw;
        margin-bottom: 2.722vw;
    }

    .training_course_lesson_check_in_complete_modal_title {
        font-size: 8.888vw;
        color: black;
        font-weight: bold;
        margin-bottom: 1.25vw;
        text-align: center;
        white-space: nowrap;
    }

    .training_course_lesson_check_in_complete_modal_description {
        font-size: 3.055vw;
        text-align: center;
        line-height: 5.277vw;
        color: black;
        margin-bottom: 3.888vw;
        max-width: 62.5vw;
    }

    .training_course_lesson_check_in_complete_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 5vw;
    }

    .training_course_lesson_check_in_complete_modal_actions>a {
        width: 62.5vw;
    }

    .training_course_lesson_check_in_complete_modal_actions>a button {
        border-radius: 5px !important;
        height: 8.888vw !important;
    }

    .training_course_lesson_check_in_complete_modal_actions>a button span {
        font-weight: 400 !important;
        font-size: 3.333vw !important;
        line-height: 4.166vw !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .training_course_lesson_check_in {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_check_in_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_course_lesson_check_in_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #DC143C;
        margin-bottom: 0.555vw;
    }

    .training_course_lesson_check_in_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.666vw;
    }

    .training_course_lesson_check_in_question {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_check_in_actions {
        display: flex;
        gap: 1.666vw;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_check_in_action_btn {
        width: 100%;
        background-color: #E5E5E5;
        border-radius: 8px;
        height: 4.444vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.25vw;
        line-height: 1.527vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: black;
        outline: none;
        border: 0px;
    }

    .training_course_lesson_check_in_action_btn.active {
        background-color: #8BD41F;
        color: white;
    }

    .training_course_lesson_check_in_action_btn.training_course_lesson_check_in_action_btn_true.active.done {
        background-color: #8BD41F;
    }

    .training_course_lesson_check_in_action_btn.training_course_lesson_check_in_action_btn_false.active.done {
        background-color: #DC143C;
    }

    .training_course_lesson_check_in_footer {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_lesson_check_in_footer.active {
        display: none;
    }

    .training_course_lesson_check_in_footer a {
        color: #5B5B62;
        white-space: nowrap;
    }

    .training_course_lesson_check_in_good_job_modal {
        display: none;
    }

    .training_course_lesson_check_in_incorrect_modal {
        display: none;
    }

    .training_course_lesson_check_in_good_job {
        background-color: #F7F8FA;
        border-radius: 8px;
        padding: 2.222vw 2.43vw 2.777vw 2.43vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_course_lesson_check_in_good_job_icon {
        width: 4.513vw;
        height: 4.513vw;
        margin-bottom: 1.041vw;
    }

    .training_course_lesson_check_in_good_job_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.708vw;
        line-height: 3.541vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #8BD41F;
        margin-bottom: 1.111vw;
    }

    .training_course_lesson_check_in_good_job_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.666vw;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 2.777vw;
    }

    .training_course_lesson_check_in_good_job_btn {
        width: 21.666vw !important;
    }

    .training_course_lesson_check_in_incorrect {
        background-color: #F7F8FA;
        border-radius: 8px;
        padding: 2.222vw 2.43vw 2.777vw 2.43vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_course_lesson_check_in_incorrect_icon {
        width: 4.513vw;
        height: 4.513vw;
        margin-bottom: 1.041vw;
    }

    .training_course_lesson_check_in_incorrect_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.708vw;
        line-height: 3.541vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #DC143C;
        margin-bottom: 1.111vw;
    }

    .training_course_lesson_check_in_incorrect_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.666vw;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 2.777vw;
    }

    .training_course_lesson_check_in_incorrect_btn {
        width: 21.666vw !important;
    }

    .training_course_lesson_check_in_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 7 / span 2;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_check_in_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_lesson_check_in_right_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .training_course_lesson_check_in_right_progress {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .training_course_lesson_check_in_right_progress_item {
        height: 2.222vw;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_check_in_right_progress_item.completed {
        color: #055CF5;
    }

    .training_course_lesson_check_in_right_progress_item:not(.with_icon) {
        padding-left: 1.944vw;
    }

    .training_course_lesson_check_in_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
    }

    .training_course_lesson_check_in_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
    }

    .training_course_lesson_check_in_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0.555vw;
        width: 0.555vw;
        border-radius: 2px;
        height: 100%;
        background-color: #055CF5;
        opacity: 0.2;
    }

    .training_course_lesson_check_in_right_progress_item.with_icon {
        gap: 0.277vw;
    }

    .training_course_lesson_check_in_right_progress_item.with_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_course_lesson_check_in_complete_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_course_lesson_check_in_complete_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_course_lesson_check_in_complete_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 12.569vw;
        left: 50%;
        transform: translateX(-50%);
        width: 36.736vw;
    }

    .training_course_lesson_check_in_complete_modal_content {
        width: 100%;
        padding: 2.662vw 1.666vw 2.013vw 1.666vw;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_course_lesson_check_in_complete_modal_icon {
        width: 10.742vw;
        height: 8.341vw;
        margin-bottom: 0.861vw;
    }

    .training_course_lesson_check_in_complete_modal_title {
        font-size: 2.777vw;
        color: black;
        font-weight: bold;
        margin-bottom: 0.384vw;
        text-align: center;
        white-space: nowrap;
    }

    .training_course_lesson_check_in_complete_modal_description {
        font-size: 1.111vw;
        text-align: center;
        line-height: 110%;
        color: black;
        margin-bottom: 2.013vw;
        max-width: 20.833vw;
    }

    .training_course_lesson_check_in_complete_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 1.597vw;
    }

    .training_course_lesson_check_in_complete_modal_actions>a {
        width: 19.861vw;
    }

    .training_course_lesson_check_in_complete_modal_actions>a button span {
        font-weight: 400;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .training_course_lesson_check_in {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_check_in_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_course_lesson_check_in_title {
        font-weight: 900;
        font-size: 0.972vw;
        line-height: 1.458vw;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #DC143C;
        margin-bottom: 0.555vw;
    }

    .training_course_lesson_check_in_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 1.666vw;
    }

    .training_course_lesson_check_in_question {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_check_in_actions {
        display: flex;
        gap: 1.666vw;
        margin-bottom: 2.222vw;
    }

    .training_course_lesson_check_in_action_btn {
        width: 100%;
        background-color: #E5E5E5;
        border-radius: 8px;
        height: 4.444vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.25vw;
        line-height: 1.527vw;
        text-align: center;
        letter-spacing: -0.01em;
        color: black;
        outline: none;
        border: 0px;
    }

    .training_course_lesson_check_in_action_btn.active {
        background-color: #8BD41F;
        color: white;
    }

    .training_course_lesson_check_in_action_btn.training_course_lesson_check_in_action_btn_true.active.done {
        background-color: #8BD41F;
    }

    .training_course_lesson_check_in_action_btn.training_course_lesson_check_in_action_btn_false.active.done {
        background-color: #DC143C;
    }

    .training_course_lesson_check_in_footer {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_lesson_check_in_footer.active {
        display: none;
    }

    .training_course_lesson_check_in_footer a {
        color: #5B5B62;
        white-space: nowrap;
    }

    .training_course_lesson_check_in_good_job_modal {
        display: none;
    }

    .training_course_lesson_check_in_incorrect_modal {
        display: none;
    }

    .training_course_lesson_check_in_good_job {
        background-color: #F7F8FA;
        border-radius: 8px;
        padding: 2.222vw 2.43vw 2.777vw 2.43vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_course_lesson_check_in_good_job_icon {
        width: 4.513vw;
        height: 4.513vw;
        margin-bottom: 1.041vw;
    }

    .training_course_lesson_check_in_good_job_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.708vw;
        line-height: 3.541vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #8BD41F;
        margin-bottom: 1.111vw;
    }

    .training_course_lesson_check_in_good_job_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.666vw;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 2.777vw;
    }

    .training_course_lesson_check_in_good_job_btn {
        width: 21.666vw !important;
    }

    .training_course_lesson_check_in_incorrect {
        background-color: #F7F8FA;
        border-radius: 8px;
        padding: 2.222vw 2.43vw 2.777vw 2.43vw;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_course_lesson_check_in_incorrect_icon {
        width: 4.513vw;
        height: 4.513vw;
        margin-bottom: 1.041vw;
    }

    .training_course_lesson_check_in_incorrect_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.708vw;
        line-height: 3.541vw;
        text-align: center;
        letter-spacing: -0.02em;
        color: #DC143C;
        margin-bottom: 1.111vw;
    }

    .training_course_lesson_check_in_incorrect_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.666vw;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 2.777vw;
    }

    .training_course_lesson_check_in_incorrect_btn {
        width: 21.666vw !important;
    }

    .training_course_lesson_check_in_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 10 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_check_in_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.222vw 1.944vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_lesson_check_in_right_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.111vw;
    }

    .training_course_lesson_check_in_right_progress {
        display: flex;
        flex-direction: column;
        gap: 0.555vw;
    }

    .training_course_lesson_check_in_right_progress_item {
        height: 2.222vw;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_check_in_right_progress_item.completed {
        color: #055CF5;
    }

    .training_course_lesson_check_in_right_progress_item:not(.with_icon) {
        padding-left: 1.944vw;
    }

    .training_course_lesson_check_in_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
    }

    .training_course_lesson_check_in_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
    }

    .training_course_lesson_check_in_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0.555vw;
        width: 0.555vw;
        border-radius: 2px;
        height: 100%;
        background-color: #055CF5;
        opacity: 0.2;
    }

    .training_course_lesson_check_in_right_progress_item.with_icon {
        gap: 0.277vw;
    }

    .training_course_lesson_check_in_right_progress_item.with_icon img {
        width: 1.666vw;
        height: 1.666vw;
    }

    .training_course_lesson_check_in_complete_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_course_lesson_check_in_complete_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_course_lesson_check_in_complete_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 12.569vw;
        left: 50%;
        transform: translateX(-50%);
        width: 36.736vw;
    }

    .training_course_lesson_check_in_complete_modal_content {
        width: 100%;
        padding: 2.662vw 1.666vw 2.013vw 1.666vw;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_course_lesson_check_in_complete_modal_icon {
        width: 10.742vw;
        height: 8.341vw;
        margin-bottom: 0.861vw;
    }

    .training_course_lesson_check_in_complete_modal_title {
        font-size: 2.777vw;
        color: black;
        font-weight: bold;
        margin-bottom: 0.384vw;
        text-align: center;
        white-space: nowrap;
    }

    .training_course_lesson_check_in_complete_modal_description {
        font-size: 1.111vw;
        text-align: center;
        line-height: 110%;
        color: black;
        margin-bottom: 2.013vw;
        max-width: 20.833vw;
    }

    .training_course_lesson_check_in_complete_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 1.597vw;
    }

    .training_course_lesson_check_in_complete_modal_actions>a {
        width: 19.861vw;
    }

    .training_course_lesson_check_in_complete_modal_actions>a button span {
        font-weight: 400;
    }
}

@media only screen and (min-width: 1441px) {
    .training_course_lesson_check_in {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_check_in_content {
        width: 100%;
        margin-left: 0px;
        padding: 40px;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 44px;
    }

    .training_course_lesson_check_in_title {
        font-weight: 900;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #DC143C;
        margin-bottom: 8px;
    }

    .training_course_lesson_check_in_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 24px;
    }

    .training_course_lesson_check_in_question {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #27272F;
        margin-bottom: 32px;
    }

    .training_course_lesson_check_in_actions {
        display: flex;
        gap: 24px;
        margin-bottom: 32px;
    }

    .training_course_lesson_check_in_action_btn {
        width: 100%;
        background-color: #E5E5E5;
        border-radius: 8px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.01em;
        color: black;
        outline: none;
        border: 0px;
    }

    .training_course_lesson_check_in_action_btn.active {
        background-color: #8BD41F;
        color: white;
    }

    .training_course_lesson_check_in_action_btn.training_course_lesson_check_in_action_btn_true.active.done {
        background-color: #8BD41F;
    }

    .training_course_lesson_check_in_action_btn.training_course_lesson_check_in_action_btn_false.active.done {
        background-color: #DC143C;
    }

    .training_course_lesson_check_in_footer {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_lesson_check_in_footer.active {
        display: none;
    }

    .training_course_lesson_check_in_footer a {
        color: #5B5B62;
        white-space: nowrap;
    }

    .training_course_lesson_check_in_good_job_modal {
        display: none;
    }

    .training_course_lesson_check_in_incorrect_modal {
        display: none;
    }

    .training_course_lesson_check_in_good_job {
        background-color: #F7F8FA;
        border-radius: 8px;
        padding: 32px 35px 40px 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_course_lesson_check_in_good_job_icon {
        width: 65px;
        height: 65px;
        margin-bottom: 15px;
    }

    .training_course_lesson_check_in_good_job_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 39px;
        line-height: 51px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #8BD41F;
        margin-bottom: 16px;
    }

    .training_course_lesson_check_in_good_job_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 40px;
    }

    .training_course_lesson_check_in_good_job_btn {
        width: 312px !important;
    }

    .training_course_lesson_check_in_incorrect {
        background-color: #F7F8FA;
        border-radius: 8px;
        padding: 32px 35px 40px 35px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_course_lesson_check_in_incorrect_icon {
        width: 65px;
        height: 65px;
        margin-bottom: 15px;
    }

    .training_course_lesson_check_in_incorrect_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 39px;
        line-height: 51px;
        text-align: center;
        letter-spacing: -0.02em;
        color: #DC143C;
        margin-bottom: 16px;
    }

    .training_course_lesson_check_in_incorrect_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #5B5B62;
        margin-bottom: 40px;
    }

    .training_course_lesson_check_in_incorrect_btn {
        width: 312px !important;
    }

    .training_course_lesson_check_in_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 10 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_lesson_check_in_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 32px 28px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_lesson_check_in_right_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 16px;
    }

    .training_course_lesson_check_in_right_progress {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .training_course_lesson_check_in_right_progress_item {
        height: 32px;
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #27272F;
    }

    .training_course_lesson_check_in_right_progress_item.completed {
        color: #055CF5;
    }

    .training_course_lesson_check_in_right_progress_item:not(.with_icon) {
        padding-left: 28px;
    }

    .training_course_lesson_check_in_right_progress_item:not(.with_icon).active::before {
        opacity: 1;
    }

    .training_course_lesson_check_in_right_progress_item.complete:not(.with_icon)::before {
        opacity: 1;
    }

    .training_course_lesson_check_in_right_progress_item:not(.with_icon)::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 8px;
        width: 8px;
        border-radius: 2px;
        height: 100%;
        background-color: #055CF5;
        opacity: 0.2;
    }

    .training_course_lesson_check_in_right_progress_item.with_icon {
        gap: 4px;
    }

    .training_course_lesson_check_in_right_progress_item.with_icon img {
        width: 24px;
        height: 24px;
    }

    .training_course_lesson_check_in_complete_modal {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    .training_course_lesson_check_in_complete_modal_backdrop {
        position: absolute;
        z-index: 10;
        background: #0D0D16;
        opacity: 0.5;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
    }

    .training_course_lesson_check_in_complete_modal_body {
        height: fit-content;
        position: absolute;
        bottom: 0px;
        z-index: 100;
        position: absolute;
        top: 181px;
        left: 50%;
        transform: translateX(-50%);
        width: 529px;
    }

    .training_course_lesson_check_in_complete_modal_content {
        width: 100%;
        padding: 38.34px 24px 29px 24px;
        background-color: white;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .training_course_lesson_check_in_complete_modal_icon {
        width: 154.69px;
        height: 120.12px;
        margin-bottom: 12.4px;
    }

    .training_course_lesson_check_in_complete_modal_title {
        font-size: 40px;
        color: black;
        font-weight: bold;
        margin-bottom: 5.54px;
        text-align: center;
        white-space: nowrap;
    }

    .training_course_lesson_check_in_complete_modal_description {
        font-size: 16px;
        text-align: center;
        line-height: 110%;
        color: black;
        margin-bottom: 29px;
        max-width: 300px;
    }

    .training_course_lesson_check_in_complete_modal_actions {
        display: flex;
        flex-direction: column;
        gap: 23px;
    }

    .training_course_lesson_check_in_complete_modal_actions>a {
        width: 286px;
    }

    .training_course_lesson_check_in_complete_modal_actions>a button span {
        font-weight: 400;
    }
}