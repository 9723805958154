@media only screen and (max-width: 480px) {
    .dashboard {
        background-color: #E6E6E6;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        min-height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .dashboard_banner {
        z-index: 1000;
        position: fixed;
        top: 0px;
        display: none;
        background-color: #00C193;
        height: 48.888vw;
        padding-top: 19.888vw;
        padding-left: 14.833vw;
        box-sizing: border-box;
        width: 100%;
    }

    .dashboard_banner_title {
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-size: 13.333vw;
        font-weight: bold;
    }

    .dashboard_banner_img {
        width: 50.555vw;
        height: 51.666vw;
        position: fixed;
        top: 2.777vw;
        right: 41.666vw;
    }

    .dashboard_body {
        flex-grow: 1;
        padding: 3.333vw 2.666vw;
        display: flex;
        flex-direction: column;
        gap: 2.777vw;
    }

    .dashboard_left {
        flex: none;
        width: 100%;
        height: 100%;
        padding-right: 0px;
    }

    .dashboard_profile_box {
        position: relative;
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 100%);
        box-shadow: 2px 3px 6px #00000029;
        border-radius: 3.888vw;
        padding: 3.333vw 3.333vw 12.777vw 3.333vw;
        margin-bottom: 11.666vw;
    }

    .dashboard_profile_box_info {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 7.5vw;
    }

    .dashboard_profile_box_avatar {
        cursor: pointer;
        flex: none;
        width: 18.333vw;
        height: 18.333vw;
        position: relative;
    }

    .dashboard_profile_box_avatar_img {
        width: 100%;
        height: 100%;
        border-radius: 9999px;
    }

    .dashboard_profile_box_avatar_badge {
        position: absolute;
        bottom: 0px;
        right: -1.111vw;
        border-radius: 9999px;
        width: 6.238vw;
        height: 6.238vw;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #A9AAAE;
        font-size: 3.333vw;
        font-weight: bold;
    }

    .dashboard_profile_box_title {
        color: white;
        font-weight: bold;
        font-size: 5.277vw;
        margin-bottom: 2.963vw;
    }

    .dashboard_profile_box_description {
        color: white;
        font-size: 3.055vw;
    }

    .dashboard_profile_box_points {
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 14px;
        display: flex;
        align-items: center;
        padding: 4.555vw 3.888vw 2.472vw 3.888vw;
        position: absolute;
        width: calc(100% - 6.666vw);
        bottom: -8.611vw;
    }

    .dashboard_profile_box_divider {
        width: 1.111vw;
        background-color: #00C193;
        height: 10.277vw;
        border-radius: 14px;
    }

    .dashboard_profile_box_point {
        width: calc(50% - 0.555vw);
        flex-grow: 1;
        text-align: center;
    }

    .dashboard_profile_box_point_score {
        font-size: 4.722vw;
        color: #000000;
        font-weight: bold;
        margin-bottom: 1.452vw;
    }

    .dashboard_profile_box_point_description {
        font-size: 3.888vw;
        font-weight: 400;
    }

    .dashboard_current_course_box {
        position: relative;
        background-color: white;
        box-shadow: 2px 3px 6px #00000029;
        padding: 2.091vw 6.033vw 2.808vw 3.763vw;
        border-radius: 14px;
    }

    .dashboard_current_course_title {
        font-size: 3.333vw;
        font-weight: bold;
        color: #000000;
        margin-bottom: 1.111vw;
    }

    .dashboard_current_course_name {
        color: #DC143C;
    }

    .dashboard_current_course_percent_graph {
        position: absolute;
        top: 50%;
        right: 6.033vw;
        transform: translateY(-50%);
    }

    .dashboard_current_course_percent_graph_container {
        flex: none;
        width: 15.555vw;
        height: 15.555vw;
        position: relative;
    }

    .dashboard_current_course_percent_graph_container .MuiCircularProgress-determinate {
        max-width: 100%;
        max-height: 100%;
    }

    .dashboard_current_course_percent_graph_circular_pg {
        top: 0;
        left: 0;
        color: #8BD41F !important;
        position: absolute;
    }

    .dashboard_current_course_percent_graph_text_pg_container {
        top: 0;
        left: 0;
        color: #000000;
        right: 0;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }

    .dashboard_current_course_percent_graph_statistic_percent_text {
        font-size: 5vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        letter-spacing: -0.02em;
    }

    .dashboard_current_course_percent_graph_statistic_percent_symbol {
        font-size: 2.777vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        padding-top: 1.666vw;
        letter-spacing: -0.02em;
    }

    .dashboard_current_course_body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 0px;
    }

    .dashboard_current_course_description {
        display: flex;
        flex-direction: column-reverse;
        gap: 1.388vw;
        font-size: 3.611vw;
        font-weight: bold;
        color: #000000;
    }

    .dashboard_points_divider {
        display: none;
        margin-top: 7.222vw;
        margin-bottom: 1.666vw;
        border-bottom: 1px solid #707070;
    }

    .dashboard_right {
        padding-left: 0px;
    }

    .dashboard_points_title {
        font-size: 4.166vw;
        font-weight: bold;
        color: #000000;
        margin-bottom: 2.638vw;
        margin-top: 2.5vw;
        padding-bottom: 1.313;
        border-bottom: 0.5px solid #515157;
    }

    .dashboard_points_title.to_earn {
        display: none;
    }

    .dashboard_points_lists {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        flex-wrap: wrap;
        gap: 3.611vw;
    }

    .dashboard_points_list {
        width: 100%;
        box-shadow: 2px 3px 6px #00000029;
        border: 3px solid #8BD41F;
        background-color: white;
        padding: 3.611vw 6.944vw 5.277vw 7.222vw;
        border-radius: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dashboard_points_list_icon {
        width: 11.111vw;
        height: 11.111vw;
        margin: auto;
        margin-bottom: 2.222vw;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }

    .dashboard_points_list_icon img {
        width: 4.444vw;
    }

    .dashboard_points_list_title {
        text-align: center;
        color: #0D0D16;
        font-size: 3.888vw;
        font-weight: bold;
        margin-bottom: 2.138vw;
    }

    .dashboard_points_list_progresses {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.555vw;
        margin-bottom: 2vw;
    }

    .dashboard_points_list_progress_item {
        background-color: #8BD41F;
        height: 1.111vw;
        border-radius: 2px;
        flex-grow: 1;
        opacity: 0.4;
    }

    .dashboard_points_list_progress_item.active {
        opacity: 1;
    }

    .dashboard_points_list_point {
        color: #FF4600;
        font-weight: bold;
        font-size: 3.333vw;
        margin-bottom: 2.722vw;
        text-transform: uppercase;
    }

    .dashboard_points_list_btn {
        background-color: black;
        color: white;
        border-radius: 5px;
        height: 6.666vw;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 0px;
        outline: none;
        font-size: 3.333vw;
        font-weight: bold;
    }

    .dashboard_points_list_btn.progress {
        background-color: black;
        color: #8BD41F;
    }

    .dashboard_points_list_btn.todo {
        background-color: black;
        color: white;
    }

    .dashboard_points_list_btn.completed {
        background-color: #8BD41F;
        color: black;
    }

    .dashboard_footer {
        position: relative;
        text-align: center;
        width: 100%;
        padding: 5.277vw 0px 7.5vw 0px;
        background-color: black;
        color: white;
        font-size: 3.611vw;
        font-weight: 600;
    }

    .dashboard_footer_logo {
        position: relative;
        width: 33.333vw;
        margin-bottom: 2.222vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .dashboard {
        background-color: #E6E6E6;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        min-height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .dashboard_banner {
        z-index: 1000;
        position: fixed;
        top: 0px;
        display: block;
        background-color: #00C193;
        height: 12.222vw;
        padding-top: 4.972vw;
        padding-left: 3.708vw;
        box-sizing: border-box;
        width: 100%;
    }

    .dashboard_banner_title {
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-size: 3.333vw;
        font-weight: bold;
    }

    .dashboard_banner_img {
        width: 12.638vw;
        height: 12.916vw;
        position: fixed;
        top: 0.694vw;
        right: 10.416vw;
    }

    .dashboard_body {
        flex-grow: 1;
        padding: 14.263vw 2.673vw 2.041vw 2.673vw;
        display: flex;
        flex-direction: row;
    }

    .dashboard_left {
        flex: none;
        width: 36.805vw;
        height: 100%;
        padding-right: 2.777vw;
    }

    .dashboard_profile_box {
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 100%);
        box-shadow: 2px 3px 6px #00000029;
        border-radius: 14px;
        padding: 1.418vw 1.582vw 0.754vw 1.636vw;
        margin-bottom: 1.388vw;
    }

    .dashboard_profile_box_info {
        display: flex;
        align-items: flex-end;
        gap: 2.607vw;
        margin-bottom: 1.111vw;
    }

    .dashboard_profile_box_avatar {
        cursor: pointer;
        flex: none;
        width: 6.458vw;
        height: 6.458vw;
        position: relative;
    }

    .dashboard_profile_box_avatar_img {
        width: 100%;
        height: 100%;
        border-radius: 9999px;
    }

    .dashboard_profile_box_avatar_badge {
        position: absolute;
        bottom: 0px;
        right: -0.277vw;
        border-radius: 9999px;
        width: 2.222vw;
        height: 2.222vw;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #A9AAAE;
        font-size: 1.25vw;
        font-weight: bold;
    }

    .dashboard_profile_box_title {
        color: white;
        font-weight: bold;
        font-size: 1.666vw;
        margin-bottom: 0.763vw;
    }

    .dashboard_profile_box_description {
        color: white;
        font-size: 0.902vw;
    }

    .dashboard_profile_box_points {
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 14px;
        display: flex;
        align-items: center;
        padding: 0.319vw 1.388vw 0.659vw 1.388vw;
    }

    .dashboard_profile_box_divider {
        width: 0.277vw;
        background-color: #00C193;
        height: 2.569vw;
        border-radius: 14px;
    }

    .dashboard_profile_box_point {
        flex-grow: 1;
        text-align: center;
    }

    .dashboard_profile_box_point_score {
        font-size: 1.527vw;
        color: #000000;
        font-weight: bold;
    }

    .dashboard_profile_box_point_description {
        font-size: 0.902vw;
        font-weight: 400;
    }

    .dashboard_current_course_box {
        box-shadow: 2px 3px 6px #00000029;
        border: 3px solid #8BD41F;
        padding: 0.833vw 0.972vw 1.847vw 0.972vw;
        border-radius: 14px;
    }

    .dashboard_current_course_title {
        font-size: 1.041vw;
        font-weight: bold;
        color: #000000;
        margin-bottom: 1.319vw;
    }

    .dashboard_current_course_name {
        color: #DC143C;
    }

    .dashboard_current_course_percent_graph {}

    .dashboard_current_course_percent_graph_container {
        flex: none;
        width: 5.694vw;
        height: 5.694vw;
        position: relative;
    }

    .dashboard_current_course_percent_graph_container .MuiCircularProgress-determinate {
        max-width: 100%;
        max-height: 100%;
    }

    .dashboard_current_course_percent_graph_circular_pg {
        top: 0;
        left: 0;
        color: #8BD41F !important;
        position: absolute;
    }

    .dashboard_current_course_percent_graph_text_pg_container {
        top: 0;
        left: 0;
        color: #000000;
        right: 0;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }

    .dashboard_current_course_percent_graph_statistic_percent_text {
        font-size: 1.527vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        letter-spacing: -0.02em;
    }

    .dashboard_current_course_percent_graph_statistic_percent_symbol {
        font-size: 0.972vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        padding-top: 0.416vw;
        letter-spacing: -0.02em;
    }

    .dashboard_current_course_body {
        display: flex;
        align-items: center;
        gap: 4.305vw;
    }

    .dashboard_current_course_description {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 1.458vw;
        font-weight: bold;
        color: #000000;
    }

    .dashboard_points_divider {
        margin-top: 1.805vw;
        margin-bottom: 0.416vw;
        border-bottom: 1px solid #707070;
    }

    .dashboard_right {
        border-left: 1px solid #707070;
        padding-left: 1.284vw;
    }

    .dashboard_points_title {
        font-size: 1.041vw;
        font-weight: bold;
        color: #000000;
        margin-bottom: 0.902vw;
    }

    .dashboard_points_lists {
        display: flex;
        flex-wrap: wrap;
        gap: 1.25vw;
    }

    .dashboard_points_list {
        width: 16.388vw;
        box-shadow: 2px 3px 6px #00000029;
        border: 0.208vw solid #8BD41F;
        background-color: white;
        padding: 1.25vw 2.5vw 1.944vw 2.5vw;
        border-radius: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dashboard_points_list_icon {
        width: 3.819vw;
        height: 3.819vw;
        margin: auto;
        margin-bottom: 0.833vw;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }

    .dashboard_points_list_icon img {
        width: 1.527vw;
    }

    .dashboard_points_list_title {
        text-align: center;
        color: #0D0D16;
        font-size: 0.972vw;
        font-weight: bold;
        margin-bottom: 0.805vw;
    }

    .dashboard_points_list_progresses {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.1875vw;
        margin-bottom: 0.694vw;
    }

    .dashboard_points_list_progress_item {
        background-color: #8BD41F;
        height: 0.375vw;
        border-radius: 2px;
        flex-grow: 1;
        opacity: 0.4;
    }

    .dashboard_points_list_progress_item.active {
        opacity: 1;
    }

    .dashboard_points_list_point {
        color: #FF4600;
        font-weight: bold;
        font-size: 0.972vw;
        margin-bottom: 1.111vw;
        text-transform: uppercase;
    }

    .dashboard_points_list_btn {
        background-color: black;
        color: white;
        border-radius: 5px;
        height: 2.222vw;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 0px;
        outline: none;
        font-size: 1.111vw;
        font-weight: bold;
    }

    .dashboard_points_list_btn.progress {
        background-color: black;
        color: #8BD41F;
    }

    .dashboard_points_list_btn.todo {
        background-color: black;
        color: white;
    }

    .dashboard_points_list_btn.completed {
        background-color: #8BD41F;
        color: black;
    }

    .dashboard_footer {
        position: relative;
        text-align: center;
        width: 100%;
        padding: 1.319vw 0px 1.875vw 0px;
        background-color: black;
        color: white;
        font-size: 0.902vw;
        font-weight: 600;
    }

    .dashboard_footer_logo {
        position: absolute;
        left: 2.569vw;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .dashboard {
        background-color: #E6E6E6;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        min-height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .dashboard_banner {
        z-index: 1000;
        position: fixed;
        top: 0px;
        display: block;
        background-color: #00C193;
        height: 12.222vw;
        padding-top: 4.972vw;
        padding-left: 3.708vw;
        box-sizing: border-box;
        width: 100%;
    }

    .dashboard_banner_title {
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-size: 3.333vw;
        font-weight: bold;
    }

    .dashboard_banner_img {
        width: 12.638vw;
        height: 12.916vw;
        position: fixed;
        top: 0.694vw;
        right: 10.416vw;
    }

    .dashboard_body {
        flex-grow: 1;
        padding: 14.263vw 2.673vw 2.041vw 2.673vw;
        display: flex;
        flex-direction: row;
    }

    .dashboard_left {
        flex: none;
        width: 36.805vw;
        height: 100%;
        padding-right: 2.777vw;
    }

    .dashboard_profile_box {
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 100%);
        box-shadow: 2px 3px 6px #00000029;
        border-radius: 14px;
        padding: 1.418vw 1.582vw 0.754vw 1.636vw;
        margin-bottom: 1.388vw;
    }

    .dashboard_profile_box_info {
        display: flex;
        align-items: flex-end;
        gap: 2.607vw;
        margin-bottom: 1.111vw;
    }

    .dashboard_profile_box_avatar {
        cursor: pointer;
        flex: none;
        width: 6.458vw;
        height: 6.458vw;
        position: relative;
    }

    .dashboard_profile_box_avatar_img {
        width: 100%;
        height: 100%;
        border-radius: 9999px;
    }

    .dashboard_profile_box_avatar_badge {
        position: absolute;
        bottom: 0px;
        right: -0.277vw;
        border-radius: 9999px;
        width: 2.222vw;
        height: 2.222vw;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #A9AAAE;
        font-size: 1.25vw;
        font-weight: bold;
    }

    .dashboard_profile_box_title {
        color: white;
        font-weight: bold;
        font-size: 1.666vw;
        margin-bottom: 0.763vw;
    }

    .dashboard_profile_box_description {
        color: white;
        font-size: 0.902vw;
    }

    .dashboard_profile_box_points {
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 14px;
        display: flex;
        align-items: center;
        padding: 0.319vw 1.388vw 0.659vw 1.388vw;
    }

    .dashboard_profile_box_divider {
        width: 0.277vw;
        background-color: #00C193;
        height: 2.569vw;
        border-radius: 14px;
    }

    .dashboard_profile_box_point {
        flex-grow: 1;
        text-align: center;
    }

    .dashboard_profile_box_point_score {
        font-size: 1.527vw;
        color: #000000;
        font-weight: bold;
    }

    .dashboard_profile_box_point_description {
        font-size: 0.902vw;
        font-weight: 400;
    }

    .dashboard_current_course_box {
        box-shadow: 2px 3px 6px #00000029;
        border: 3px solid #8BD41F;
        padding: 0.833vw 0.972vw 1.847vw 0.972vw;
        border-radius: 14px;
    }

    .dashboard_current_course_title {
        font-size: 1.041vw;
        font-weight: bold;
        color: #000000;
        margin-bottom: 1.319vw;
    }

    .dashboard_current_course_name {
        color: #DC143C;
    }

    .dashboard_current_course_percent_graph {}

    .dashboard_current_course_percent_graph_container {
        flex: none;
        width: 5.694vw;
        height: 5.694vw;
        position: relative;
    }

    .dashboard_current_course_percent_graph_container .MuiCircularProgress-determinate {
        max-width: 100%;
        max-height: 100%;
    }

    .dashboard_current_course_percent_graph_circular_pg {
        top: 0;
        left: 0;
        color: #8BD41F !important;
        position: absolute;
    }

    .dashboard_current_course_percent_graph_text_pg_container {
        top: 0;
        left: 0;
        color: #000000;
        right: 0;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }

    .dashboard_current_course_percent_graph_statistic_percent_text {
        font-size: 1.527vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        letter-spacing: -0.02em;
    }

    .dashboard_current_course_percent_graph_statistic_percent_symbol {
        font-size: 0.972vw;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        padding-top: 0.416vw;
        letter-spacing: -0.02em;
    }

    .dashboard_current_course_body {
        display: flex;
        align-items: center;
        gap: 4.305vw;
    }

    .dashboard_current_course_description {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 1.458vw;
        font-weight: bold;
        color: #000000;
    }

    .dashboard_points_divider {
        margin-top: 1.805vw;
        margin-bottom: 0.416vw;
        border-bottom: 1px solid #707070;
    }

    .dashboard_right {
        border-left: 1px solid #707070;
        padding-left: 1.284vw;
    }

    .dashboard_points_title {
        font-size: 1.041vw;
        font-weight: bold;
        color: #000000;
        margin-bottom: 0.902vw;
    }

    .dashboard_points_lists {
        display: flex;
        flex-wrap: wrap;
        gap: 1.25vw;
    }

    .dashboard_points_list {
        width: 16.388vw;
        box-shadow: 2px 3px 6px #00000029;
        border: 0.208vw solid #8BD41F;
        background-color: white;
        padding: 1.25vw 2.5vw 1.944vw 2.5vw;
        border-radius: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dashboard_points_list_icon {
        width: 3.819vw;
        height: 3.819vw;
        margin: auto;
        margin-bottom: 0.833vw;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }

    .dashboard_points_list_icon img {
        width: 1.527vw;
    }

    .dashboard_points_list_title {
        text-align: center;
        color: #0D0D16;
        font-size: 0.972vw;
        font-weight: bold;
        margin-bottom: 0.805vw;
    }

    .dashboard_points_list_progresses {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.1875vw;
        margin-bottom: 0.694vw;
    }

    .dashboard_points_list_progress_item {
        background-color: #8BD41F;
        height: 0.375vw;
        border-radius: 2px;
        flex-grow: 1;
        opacity: 0.4;
    }

    .dashboard_points_list_progress_item.active {
        opacity: 1;
    }

    .dashboard_points_list_point {
        color: #FF4600;
        font-weight: bold;
        font-size: 0.972vw;
        margin-bottom: 1.111vw;
        text-transform: uppercase;
    }

    .dashboard_points_list_btn {
        background-color: black;
        color: white;
        border-radius: 5px;
        height: 2.222vw;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 0px;
        outline: none;
        font-size: 1.111vw;
        font-weight: bold;
    }

    .dashboard_points_list_btn.progress {
        background-color: black;
        color: #8BD41F;
    }

    .dashboard_points_list_btn.todo {
        background-color: black;
        color: white;
    }

    .dashboard_points_list_btn.completed {
        background-color: #8BD41F;
        color: black;
    }

    .dashboard_footer {
        position: relative;
        text-align: center;
        width: 100%;
        padding: 1.319vw 0px 1.875vw 0px;
        background-color: black;
        color: white;
        font-size: 0.902vw;
        font-weight: 600;
    }

    .dashboard_footer_logo {
        position: absolute;
        left: 2.569vw;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media only screen and (min-width: 1441px) {
    .dashboard {
        background-color: #E6E6E6;
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        min-height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
    }

    .dashboard_banner {
        z-index: 1000;
        position: fixed;
        top: 0px;
        display: block;
        background-color: #00C193;
        height: 176px;
        padding-top: 71.6px;
        padding-left: 53.4px;
        box-sizing: border-box;
        width: 100%;
    }

    .dashboard_banner_title {
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-size: 48px;
        font-weight: bold;
    }

    .dashboard_banner_img {
        width: 182px;
        height: 186px;
        position: fixed;
        top: 10px;
        right: 150px;
    }

    .dashboard_body {
        flex-grow: 1;
        padding: 205.4px 38.5px 29.4px 38.5px;
        display: flex;
        flex-direction: row;
    }

    .dashboard_left {
        flex: none;
        width: 530px;
        height: 100%;
        padding-right: 40px;
    }

    .dashboard_profile_box {
        background: linear-gradient(180deg, #8BD41F 0%, #00C193 100%);
        box-shadow: 2px 3px 6px #00000029;
        border-radius: 14px;
        padding: 20.43px 22.79px 18.07px 23.57px;
        margin-bottom: 20px;
    }

    .dashboard_profile_box_info {
        display: flex;
        align-items: flex-end;
        gap: 37.55px;
        margin-bottom: 16px;
    }

    .dashboard_profile_box_avatar {
        cursor: pointer;
        flex: none;
        width: 93px;
        height: 93px;
        position: relative;
    }

    .dashboard_profile_box_avatar_img {
        width: 100%;
        height: 100%;
        border-radius: 9999px;
    }

    .dashboard_profile_box_avatar_badge {
        position: absolute;
        bottom: 0px;
        right: -4px;
        border-radius: 9999px;
        width: 32px;
        height: 32px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #A9AAAE;
        font-size: 18px;
        font-weight: bold;
    }

    .dashboard_profile_box_title {
        color: white;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 11px;
    }

    .dashboard_profile_box_description {
        color: white;
        font-size: 13px;
    }

    .dashboard_profile_box_points {
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 14px;
        display: flex;
        align-items: center;
        padding: 4.6px 20px 9.5px 20px;
    }

    .dashboard_profile_box_divider {
        width: 4px;
        background-color: #00C193;
        height: 37px;
        border-radius: 14px;
    }

    .dashboard_profile_box_point {
        flex-grow: 1;
        text-align: center;
    }

    .dashboard_profile_box_point_score {
        font-size: 22px;
        color: #000000;
        font-weight: bold;
    }

    .dashboard_profile_box_point_description {
        font-size: 13px;
        font-weight: 400;
    }

    .dashboard_current_course_box {
        box-shadow: 2px 3px 6px #00000029;
        border: 3px solid #8BD41F;
        padding: 12px 14px 26.6px 14px;
        border-radius: 14px;
    }

    .dashboard_current_course_title {
        font-size: 15px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 19px;
    }

    .dashboard_current_course_name {
        color: #DC143C;
    }

    .dashboard_current_course_percent_graph {}

    .dashboard_current_course_percent_graph_container {
        flex: none;
        width: 82px;
        height: 82px;
        position: relative;
    }

    .dashboard_current_course_percent_graph_container .MuiCircularProgress-determinate {
        max-width: 100%;
        max-height: 100%;
    }

    .dashboard_current_course_percent_graph_circular_pg {
        top: 0;
        left: 0;
        color: #8BD41F !important;
        position: absolute;
    }

    .dashboard_current_course_percent_graph_text_pg_container {
        top: 0;
        left: 0;
        color: #000000;
        right: 0;
        bottom: 0;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
    }

    .dashboard_current_course_percent_graph_statistic_percent_text {
        font-size: 22px;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        letter-spacing: -0.02em;
    }

    .dashboard_current_course_percent_graph_statistic_percent_symbol {
        font-size: 14px;
        font-family: 'Azo Sans', sans-serif !important;
        font-weight: 700;
        padding-top: 6px;
        letter-spacing: -0.02em;
    }

    .dashboard_current_course_body {
        display: flex;
        align-items: center;
        gap: 62px;
    }

    .dashboard_current_course_description {
        display: flex;
        flex-direction: column;
        gap: 5px;
        font-size: 21px;
        font-weight: bold;
        color: #000000;
    }

    .dashboard_points_divider {
        margin-top: 26px;
        margin-bottom: 6px;
        border-bottom: 1px solid #707070;
    }

    .dashboard_right {
        border-left: 1px solid #707070;
        padding-left: 18.5px;
    }

    .dashboard_points_title {
        font-size: 15;
        font-weight: bold;
        color: #000000;
        margin-bottom: 13px;
    }

    .dashboard_points_lists {
        display: flex;
        flex-wrap: wrap;
        gap: 18px;
    }

    .dashboard_points_list {
        width: 236px;
        box-shadow: 2px 3px 6px #00000029;
        border: 3px solid #8BD41F;
        background-color: white;
        padding: 18px 36px 28px 36px;
        border-radius: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dashboard_points_list_icon {
        width: 55px;
        height: 55px;
        margin: auto;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
    }

    .dashboard_points_list_icon img {
        width: 22px;
    }

    .dashboard_points_list_title {
        text-align: center;
        color: #0D0D16;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 11.6px;
    }

    .dashboard_points_list_progresses {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 2.7px;
        margin-bottom: 10px;
    }

    .dashboard_points_list_progress_item {
        background-color: #8BD41F;
        height: 5.4px;
        border-radius: 2px;
        flex-grow: 1;
        opacity: 0.4;
    }

    .dashboard_points_list_progress_item.active {
        opacity: 1;
    }

    .dashboard_points_list_point {
        color: #FF4600;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 16px;
        text-transform: uppercase;
    }

    .dashboard_points_list_btn {
        background-color: black;
        color: white;
        border-radius: 5px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 0px;
        outline: none;
        font-size: 16px;
        font-weight: bold;
    }

    .dashboard_points_list_btn.progress {
        background-color: black;
        color: #8BD41F;
    }

    .dashboard_points_list_btn.todo {
        background-color: black;
        color: white;
    }

    .dashboard_points_list_btn.completed {
        background-color: #8BD41F;
        color: black;
    }

    .dashboard_footer {
        position: relative;
        text-align: center;
        width: 100%;
        padding: 19px 0px 27px 0px;
        background-color: black;
        color: white;
        font-size: 13px;
        font-weight: 600;
    }

    .dashboard_footer_logo {
        position: absolute;
        left: 37px;
        top: 50%;
        transform: translateY(-50%);
    }
}