@media only screen and (max-width: 480px) {
    .training_course_terms_to_know {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 1 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_terms_to_know_content {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 6.666vw 4.444vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 0px;
    }

    .training_course_terms_to_know_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 6.666vw;
        line-height: 8.611vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 4.444vw;
    }

    .training_course_terms_to_know_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 7.222vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 6.666vw;
    }

    .training_course_terms_to_know_familar_items {
        display: flex;
        flex-direction: column;
        gap: 6.666vw;
        margin-bottom: 8.888vw;
    }

    .training_course_terms_to_know_familar_item_title {
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        margin-bottom: 1.111vw;
        color: #0D0D16;
    }

    .training_course_terms_to_know_familar_item_description {
        font-weight: 400;
        font-size: 4.444vw;
        line-height: 7.222vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_terms_to_know_familar_items>div:last-child .training_course_terms_to_know_familar_item {
        margin-bottom: 0px;
    }

    .training_course_terms_to_know_familar_item {
        margin-bottom: 6.666vw;
    }

    .training_course_terms_to_know_footer .btn {
        width: 100%;
        margin-left: auto;
    }

    .training_course_terms_to_know_right {
        display: none;
    }

    .training_course_terms_to_know_index_items {
        display: flex;
        flex-direction: column;
        gap: 4.444vw;
    }

    .training_course_terms_to_know_index_item {
        font-weight: 500;
        font-size: 4.444vw;
        line-height: 6.666vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .training_course_terms_to_know_familar_item_divider {
        border-bottom: 1px solid #DDDEE1;
        margin-top: 4.444vw;
    }
}

@media only screen and (min-width: 481px) and (max-width: 840px) {
    .training_course_terms_to_know {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 3 / span 4;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_terms_to_know_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_course_terms_to_know_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 1.111vw;
    }

    .training_course_terms_to_know_description {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.222vw;
    }

    .training_course_terms_to_know_familar_items {
        display: flex;
        flex-direction: column;
        gap: 1.666vw;
        margin-bottom: 2.5vw;
    }

    .training_course_terms_to_know_familar_item_title {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
        color: #0D0D16;
    }

    .training_course_terms_to_know_familar_item_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_terms_to_know_familar_items>div:last-child .training_course_terms_to_know_familar_item {
        margin-bottom: 1.666vw;
    }

    .training_course_terms_to_know_familar_item {
        margin-bottom: 1.666vw;
    }

    .training_course_terms_to_know_footer .btn {
        width: 13.888vw;
        margin-left: auto;
    }

    .training_course_terms_to_know_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 7 / span 2;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_terms_to_know_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.222vw 2.222vw 2.777vw 2.222vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_terms_to_know_right_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.666vw;
    }

    .training_course_terms_to_know_index_items {
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
    }

    .training_course_terms_to_know_index_item {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .training_course_terms_to_know_familar_item_divider {
        border-bottom: 1px solid #DDDEE1;
        margin-top: 1.111vw;
    }
}

@media only screen and (min-width: 841px) and (max-width: 1440px) {
    .training_course_terms_to_know {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_terms_to_know_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.777vw;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 3.055vw;
    }

    .training_course_terms_to_know_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 2.152vw;
        line-height: 2.777vw;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 1.111vw;
    }

    .training_course_terms_to_know_description {
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 2.222vw;
    }

    .training_course_terms_to_know_familar_items {
        display: flex;
        flex-direction: column;
        gap: 1.666vw;
        margin-bottom: 2.5vw;
    }

    .training_course_terms_to_know_familar_item_title {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
        color: #0D0D16;
    }

    .training_course_terms_to_know_familar_item_description {
        font-weight: 400;
        font-size: 1.111vw;
        line-height: 1.805vw;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_terms_to_know_familar_items>div:last-child .training_course_terms_to_know_familar_item {
        margin-bottom: 1.666vw;
    }

    .training_course_terms_to_know_familar_item {
        margin-bottom: 1.666vw;
    }

    .training_course_terms_to_know_footer .btn {
        width: 13.888vw;
        margin-left: auto;
    }

    .training_course_terms_to_know_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 10 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_terms_to_know_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 2.222vw 2.222vw 2.777vw 2.222vw;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_terms_to_know_right_title {
        font-weight: 700;
        font-size: 1.388vw;
        line-height: 2.083vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 1.666vw;
    }

    .training_course_terms_to_know_index_items {
        display: flex;
        flex-direction: column;
        gap: 1.111vw;
    }

    .training_course_terms_to_know_index_item {
        font-weight: 500;
        font-size: 1.111vw;
        line-height: 1.666vw;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .training_course_terms_to_know_familar_item_divider {
        border-bottom: 1px solid #DDDEE1;
        margin-top: 1.111vw;
    }
}

@media only screen and (min-width: 1441px) {
    .training_course_terms_to_know {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 4 / span 6;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_terms_to_know_content {
        width: 100%;
        margin-left: 0px;
        padding: 40px;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 44px;
    }

    .training_course_terms_to_know_title {
        font-family: 'Azo Sans', sans-serif;
        font-weight: 700;
        font-size: 31px;
        line-height: 40px;
        letter-spacing: -0.02em;
        color: #27272F;
        margin-bottom: 16px;
    }

    .training_course_terms_to_know_description {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #5B5B62;
        margin-bottom: 32px;
    }

    .training_course_terms_to_know_familar_items {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 32px;
    }

    .training_course_terms_to_know_familar_item_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        margin-bottom: 0px;
        color: #0D0D16;
    }

    .training_course_terms_to_know_familar_item_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: #5B5B62;
    }

    .training_course_terms_to_know_familar_items>div:last-child .training_course_terms_to_know_familar_item {
        margin-bottom: 24px;
    }

    .training_course_terms_to_know_familar_item {
        margin-bottom: 24px;
    }

    .training_course_terms_to_know_footer .btn {
        width: 200px;
        margin-left: auto;
    }

    .training_course_terms_to_know_right {
        font-family: 'Circular Std Bk', 'Azo Sans', sans-serif;
        grid-column: 10 / span 3;
        grid-row: 1;
        width: 100%;
        z-index: 3;
        display: block;
    }

    .training_course_terms_to_know_right_content {
        width: 100%;
        margin-left: 0px;
        padding: 32px 32px 40px 32px;
        background: #FFFFFF;
        border-radius: 16px;
    }

    .training_course_terms_to_know_right_title {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.01em;
        color: #0D0D16;
        margin-bottom: 24px;
    }

    .training_course_terms_to_know_index_items {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .training_course_terms_to_know_index_item {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: #0D0D16;
    }

    .training_course_terms_to_know_familar_item_divider {
        border-bottom: 1px solid #DDDEE1;
        margin-top: 16px;
    }
}